import React, { useState, useEffect, useRef } from "react";
import { useToast, ToastProvider } from 'rc-toastr';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/images/Logo.png';


const MainWebAppWrap = () => {
    const user_webapp = localStorage.getItem("user_webapp");

    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="membership-wrap">
                            <Link className="navbar-brand" to="/">
                                <img src={Logo} alt="Logo" />
                            </Link>
                            <div className="membership-item gap-2">
                                <Link to="/package" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >Package Topup</Link>
                                <Link to="/history" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >History Topup</Link>
                                {/* <Link to="/referral" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >Package Info</Link> */}
                                {/* <Link to="/membership" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >Membership</Link> */}
                                <Link to="/referral-list" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >List Referral</Link>
                                <Link to="/commission-topup" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >Commission Topup</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MainWebAppWrap;
