import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Unity, useUnityContext, RegisterExternalListener } from "react-unity-webgl";

const PlayGameMobile = ({ jsonString }) => {

    const {
        unityProvider, requestFullscreen, isLoaded, loadingProgression,
        addEventListener, removeEventListener, sendMessage
    } = useUnityContext({
        loaderUrl: `${process.env.PUBLIC_URL}/playGame/build/Build.loader.js`,
        dataUrl: `${process.env.PUBLIC_URL}/playGame/build/webgl.data`,
        frameworkUrl: `${process.env.PUBLIC_URL}/playGame/build/build.framework.js`,
        codeUrl: `${process.env.PUBLIC_URL}/playGame/build/build.wasm`,
        // streamingAssetsUrl: "streamingassets",
        // webGLContextAttributes: {
        //     alpha: true,
        //     antialias: true,
        //     depth: true,
        //     failIfMajorPerformanceCaveat: true,
        //     powerPreference: "high-performance",
        //     premultipliedAlpha: true,
        //     preserveDrawingBuffer: true,
        //     stencil: true,
        //     desynchronized: true,
        //     xrCompatible: true,
        // },
    });

    const loadingPercentage = Math.round(loadingProgression * 100);
    const unityRef = useRef();

    const refreshPage = () => {
        window.location.reload(false);
    }

    /// From React to Unity
    //  gameObjectName: GameManager,
    //  methodName: MessageFromjavaScript,
    //  parameter: message
    function handleLoadFirstData(isLoaded) {

        if (isLoaded) {
            sendMessage("GameManager", "MessageFromjavaScript", jsonString);
            //sendMessage("GameManager", "MessageFromjavaScript", 'message123456');
        }
    }

    // From Unity To React
    const returnUnityToReact = useCallback((message) => {
        if (message === 'reloadPage') {
            refreshPage();
        }
        // if (message === 'duckVibrate') {   
        //     console.log('123456789')        
        //     navigator.vibrate(1000);
        // }
    }, []);

    const returnDuckVibrate = useCallback((message) => {
        console.log(message)
        window.navigator.vibrate(parseInt(message));
    }, []);

    useEffect(() => {
        addEventListener("gamePlay", returnUnityToReact);
        return () => {
            removeEventListener("gamePlay", returnUnityToReact);
        };
    }, [addEventListener, removeEventListener, returnUnityToReact]);

    useEffect(() => {
        addEventListener("duckVibrate", returnDuckVibrate);
        return () => {
            removeEventListener("duckVibrate", returnDuckVibrate);
        };
    }, [addEventListener, removeEventListener, returnDuckVibrate]);

    useEffect(() => {
        handleLoadFirstData(isLoaded);
    }, [isLoaded]);


    return (
        <>
            {!isLoaded &&
                <div className='loading'>
                    <h3>Loading... ({loadingPercentage}%)</h3>
                </div>
            }
            <div ref={unityRef} className='text-center game-wrap'>
                <Unity
                    unityProvider={unityProvider}
                    style={{
                        height: '100%',
                        width: '100%',
                        visibility: isLoaded ? "visible" : "hidden"
                    }}
                />
            </div>
        </>
    );
};
const PlayGameWebApp = ({ jsonString }) => {
    return (
        <PlayGameMobile jsonString={jsonString} />
    );
};

export default PlayGameWebApp;