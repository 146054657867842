import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';
import { QRCode } from 'react-qrcode-logo';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FcInfo } from "react-icons/fc";
import {
    FiCopy
} from "react-icons/fi";

import WithDraw from "./withDraw";
import ConvertTplantToTon from "./convertTplantToTon";
import TransferTfarm from "./transferTfarm";
import Ton_Icon from '../../assets/images/icons/ton_symbol.svg';

import {
    ConvertTONtoTFARM,
    GetTONPrice,
    GetHistoryConvert,
    GetInfo
} from '../../api/index';

import { useUser } from '../../context/UserContext';

const DashBoardMain = () => {
    const token = localStorage.getItem("token");
    const { toast } = useToast();
    const { user, setUser } = useUser();
    const [tonPrice, setTonPrice] = useState(0);

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied successfully');
        } catch (err) {
            toast.warning('Copy failed');
        }
    };

    useEffect(() => {
        const fetchTonPrice = async () => {
            try {
                const response = await GetTONPrice(token);
                const responseInfo = await GetInfo(token);

                const userData = {
                    id: responseInfo.id,
                    username: responseInfo.lastname + " " + responseInfo.firstname,
                    email: responseInfo.email,
                    photo: responseInfo.photo,
                    hexAddress: responseInfo.hexAddress,
                    bounceableAddress: responseInfo.bounceableAddress,
                    createdAtTimestamp: responseInfo.createdAtTimestamp,
                    TFARM_Balance: responseInfo.TFARM_Balance,
                    TON_Balance: responseInfo.TON_Balance,
                    TPLANT_Balance: responseInfo.TPLANT_Balance,
                    isActived: responseInfo.isActived,
                };
                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));
                setTonPrice(response.TON_prices_USDT ? response.TON_prices_USDT : 0);
            } catch (error) {
                // console.error(error.message);
            }
        };
        fetchTonPrice();
    }, []);

    return (
        <React.Fragment>
            <div className="container convert-desktop-wrap">
                <div className="row">
                    <div className="col-12 mb-5">
                        <Tabs
                            defaultActiveKey="withDraw"
                            className="mb-3"
                            variant="pills"
                        >
                            <Tab className="box-pixel-wrap" eventKey="withDraw" title={<><FcInfo className="me-2" />Deposit To Game</>}>
                                <Tabs
                                    defaultActiveKey="ConvertTON"
                                    className="mb-3"
                                >
                                    <Tab eventKey="Deposit" title={<>Deposit</>}>
                                        <span className="d-block mb-3 text-center">Deposit Address: <span className="text-primary hexAddress">{user && user.bounceableAddress}<FiCopy size={20} title="Sao chép nội dung" className="ms-2 copy text-warning"  onClick={() => copyToClipBoard(user && user.bounceableAddress)} /></span></span>
                                        <div className='text-center d-flex align-items-center justify-content-center flex-column qr-code-ton'>
                                            <QRCode
                                                value={user && user.bounceableAddress}
                                                size={200}
                                                logoImage={Ton_Icon}
                                                qrStyle='dots'
                                                logoWidth={50}
                                                bgColor='#FFFFFF'
                                                logoOpacity={1}
                                                fgColor='#000000'
                                                quietZone={10}
                                                ecLevel='L'
                                                eyeRadius={100}
                                            />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="ConvertTON" title={<>Convert TON to TFARM</>}>
                                        <WithDraw userInfo={user} tonPrice={tonPrice} />
                                    </Tab>
                                    <Tab eventKey="ConvertTPLANT" title={<>Convert TPLANT to TON</>}>
                                        <ConvertTplantToTon userInfo={user} tonPrice={tonPrice} />
                                    </Tab>
                                </Tabs>
                            </Tab>
                            <Tab eventKey="transferTfarm" title={<><FcInfo className="me-2" />Transfer Tfarm</>}>
                                <TransferTfarm userInfo={user} tonPrice={tonPrice} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DashBoardMain;
