import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';
import { QRCode } from 'react-qrcode-logo';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FcInfo } from "react-icons/fc";
import {
    FiCopy
} from "react-icons/fi";

import WithDraw from "./withDraw";
import ConvertTplantToTon from "./convertTplantToTon";
import TransferTfarm from "./transferTfarm";
import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';
import Logo from '../../../assets/images/Logo.png';

import {
    ConvertTONtoTFARM,
    GetTONPrice,
    GetHistoryConvert,
    GetInfo
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';


const Deposit = () => {

    const { user } = useUser();

    const { toast } = useToast();

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied successfully');
        } catch (err) {
            toast.warning('Copy failed');
        }
    };

    return (
        <React.Fragment>
            <div className='text-center d-flex align-items-center justify-content-center flex-column qr-code-ton qr-code-ton-webapp'>
                <div className='d-flex align-items-center justify-content-center gap-2 mb-3'>
                    <Link to="/convert" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Home</Link>
                </div>
            <span className="d-block mb-3 text-center p-2">Deposit Address: <span className="text-primary hexAddress">{user && user.bounceableAddress}<FiCopy size={20} title="Sao chép nội dung" className="ms-2 copy text-warning"  onClick={() => copyToClipBoard(user && user.bounceableAddress)} /></span></span>
                <QRCode
                    value={user && user.bounceableAddress}
                    size={200}
                    logoImage={Ton_Icon}
                    qrStyle='dots'
                    logoWidth={50}
                    bgColor='#FFFFFF'
                    logoOpacity={1}
                    fgColor='#000000'
                    quietZone={10}
                    ecLevel='L'
                    eyeRadius={100}
                />
            </div>
        </React.Fragment>
    );
}

export default Deposit;
