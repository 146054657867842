import React, { useState, useEffect, useRef } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import moment from 'moment';
import 'moment-timezone';

import { HiOutlineBuildingStorefront } from "react-icons/hi2";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';

import MyItemsAppWrap from "./myItems";

import {
    ClaimQuest,
    GetQuestInfo,
    GetListReferral
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';

import Carot_Icon from '../../../assets/images/icons/Carrot.png';
import Beetroot from '../../../assets/images/items/beetroot.png';
import Artichoke from '../../../assets/images/items/artichoke.png';
import Bell_Pepper from '../../../assets/images/items/Bell Pepper.png';
import Bok_Choy from '../../../assets/images/items/bok choy.png';
import Broccoli from '../../../assets/images/items/broccoli.png';
import Brussels_Sprout from '../../../assets/images/items/brussels sprout.png';
import Cabbage from '../../../assets/images/items/cabbage.png';
import Carrot from '../../../assets/images/items/carrot.png';
import Cauliflower from '../../../assets/images/items/cauliflower.png';
import Corn from '../../../assets/images/items/corn.png';
import Eggplant from '../../../assets/images/items/eggplant.png';
import Galic from '../../../assets/images/items/galic.png';
import Leek from '../../../assets/images/items/leek.png';
import Onion from '../../../assets/images/items/Onion.png';
import Parsnip from '../../../assets/images/items/parsnip.png';
import Potato from '../../../assets/images/items/potato.png';
import Radish from '../../../assets/images/items/radish.png';
import Red_Cabbage from '../../../assets/images/items/Red cabbage.png';
import Snappeas from '../../../assets/images/items/snappeas.png';
import Spinach from '../../../assets/images/items/spinach.png';
import Stawberry from '../../../assets/images/items/stawberry.png';
import Sunflower from '../../../assets/images/items/sunflower.png';
import Tomato from '../../../assets/images/items/tomato.png';
import Turnip from '../../../assets/images/items/turnip.png';
import Wheat from '../../../assets/images/items/wheat.png';
import Pot_1 from '../../../assets/images/items/pots/Pots 1.png';
import Pot_2 from '../../../assets/images/items/pots/Pots 2.png';
import Pot_3 from '../../../assets/images/items/pots/Pots 3.png';
import Pot_4 from '../../../assets/images/items/pots/Pots 4.png';
import Pot_5 from '../../../assets/images/items/pots/Pots 5.png';
import Pot from '../../../assets/images/items/pots/Pots.png';


const MarketAppWrap = () => {

    const maxLength = 45;

    const user_webapp = localStorage.getItem("user_webapp");

    const token = localStorage.getItem("token");
    const { toast } = useToast();
    const { user, setUser } = useUser();

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleShowModalBuy = () => {
        setShow(true);
    }

    var chuoiDai = "UQARRT0dulcF0Uz1ExwtWJOSnmuLj7vkrYKcwHLkN2-r4iwl";
    var chuoiRutGon = chuoiDai.substring(0, 3) + "..." + chuoiDai.substring(chuoiDai.length - 6);

    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="market-wrap pt-3">
                            <div className="market-container gap-2">
                                <div className="market-header">
                                    <HiOutlineBuildingStorefront />
                                    <span>Store</span>
                                </div>
                                <Tabs
                                    defaultActiveKey="Crops"
                                    className="mb-3 mt-3"
                                    variant="pills"
                                >
                                    <Tab eventKey="Crops" title={<>CROPS</>} className="market-body-wrap">
                                        <div className="market-body">
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Beetroot
                                                </div>
                                                <div className="prod-img"><img src={Beetroot} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn" onClick={handleShowModalBuy}>Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Artichoke
                                                </div>
                                                <div className="prod-img"><img src={Artichoke} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Bell Pepper
                                                </div>
                                                <div className="prod-img"><img src={Bell_Pepper} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Bok Choy
                                                </div>
                                                <div className="prod-img"><img src={Bok_Choy} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Broccoli
                                                </div>
                                                <div className="prod-img"><img src={Broccoli} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Brussels Sprout
                                                </div>
                                                <div className="prod-img"><img src={Brussels_Sprout} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Cauliflower
                                                </div>
                                                <div className="prod-img"><img src={Cauliflower} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Cabbage
                                                </div>
                                                <div className="prod-img"><img src={Cabbage} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                    Carrot
                                                </div>
                                                <div className="prod-img"><img src={Carrot} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Corn
                                                </div>
                                                <div className="prod-img"><img src={Corn} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Eggplant
                                                </div>
                                                <div className="prod-img"><img src={Eggplant} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Galic
                                                </div>
                                                <div className="prod-img"><img src={Galic} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Leek
                                                </div>
                                                <div className="prod-img"><img src={Leek} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Onion
                                                </div>
                                                <div className="prod-img"><img src={Onion} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Parsnip
                                                </div>
                                                <div className="prod-img"><img src={Parsnip} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Potato
                                                </div>
                                                <div className="prod-img"><img src={Potato} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Radish
                                                </div>
                                                <div className="prod-img"><img src={Radish} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Red Cabbage
                                                </div>
                                                <div className="prod-img"><img src={Red_Cabbage} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Snappeas
                                                </div>
                                                <div className="prod-img"><img src={Snappeas} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Spinach
                                                </div>
                                                <div className="prod-img"><img src={Spinach} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Stawberry
                                                </div>
                                                <div className="prod-img"><img src={Stawberry} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Sunflower
                                                </div>
                                                <div className="prod-img"><img src={Sunflower} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Tomato
                                                </div>
                                                <div className="prod-img"><img src={Tomato} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Turnip
                                                </div>
                                                <div className="prod-img"><img src={Turnip} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                Wheat
                                                </div>
                                                <div className="prod-img"><img src={Wheat} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Pot" title={<>POT</>} className="market-body-wrap">
                                    <div className="market-body">
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                    Pot
                                                </div>
                                                <div className="prod-img"><img src={Pot} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <span className="buyer">Buyer: <span>{user ?  user.id : ""}</span></span>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                    Pot 1
                                                </div>
                                                <div className="prod-img"><img src={Pot_1} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <span className="buyer">Buyer: <span>{user ?  user.id : ""}</span></span>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                    Pot 1
                                                </div>
                                                <div className="prod-img"><img src={Pot_2} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <span className="buyer">Buyer: <span>{user ?  user.id : ""}</span></span>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                    Pot 3
                                                </div>
                                                <div className="prod-img"><img src={Pot_3} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <span className="buyer">Buyer: <span>{user ?  user.id : ""}</span></span>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                    Pot 4
                                                </div>
                                                <div className="prod-img"><img src={Pot_4} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <span className="buyer">Buyer: <span>{user ?  user.id : ""}</span></span>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                            <div className="prod-item">
                                                <div className="prod-title">
                                                    Pot 5
                                                </div>
                                                <div className="prod-img"><img src={Pot_5} alt="" /></div>
                                                <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                                                <span className="buyer">Buyer: <span>{user ?  user.id : ""}</span></span>
                                                <button className="btn pixel-btn">Buy</button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Inventory" title={<>INVENTORY</>} className="market-body-wrap">
                                        <MyItemsAppWrap />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Buy Item</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="d-flex align-items-center gap-2">
                        <button className="btn pixel-btn btn-primary mt-2 w-100">yes</button>
                        <button className="btn pixel-btn btn-dark mt-2 w-100" onClick={handleClose}>No</button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default MarketAppWrap;
