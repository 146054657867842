import React, { useState, useEffect, useRef } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from 'axios';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import OtpInput from 'react-otp-input';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import { useToast, ToastProvider } from 'rc-toastr';

import TCoin_Icon from '../../../assets/images/icons/FCoin.png';
import FCoin_Icon from '../../../assets/images/icons/FCoin_t.png';
import TON_Icon from '../../../assets/images/icons/ton_symbol.svg';
import Loss_Icon from '../../../assets/images/icons/cloudy.png';
import Pixel_Icon from '../../../assets/images/icons/pixel.png';

import {
    CreatePincode,
    ShowSeedPharse,
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';

import {
    FiCopy,
    FiX
} from "react-icons/fi";

const WalletAppItemWrap = ({tonAccountBalance}) => {

    const maxLength = 25;

    const token = localStorage.getItem("token");
    const telegramWebApp = localStorage.getItem("telegramWebApp");

    const result_telegramWebApp = telegramWebApp.replace(/"/g, '');
    

    const { toast } = useToast();
    const { user, setUser } = useUser();

    const [balanceTPLANT, setBalanceTPLANT] = useState(0);

    const [pin, setPin] = useState('');
    const [pinConfirm, setPinConfirm] = useState('');
    const [loadingSend, setLoadingSend] = useState(false);

    const [showPrivateKey, setShowPrivateKey] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [show, setShow] = useState(false);
    const [error, setError] = useState('');

    const [seedPharse, setSeedPharse] = useState([]);

    const handleClose = () => {
        setShow(false);
    };

    const handleOTPChange = (pin) => {
        setPin(pin);
        if (/^\d*$/.test(pin) && pin.length <= 6) {
            setPin(pin);
            setError('');
        } else {
            setError('PIN must be a number and have at most 6 digits.');
        }
    }

    const handleShowKey = () => {
        setShowPrivateKey(true);
    }

    const handleShowModal = () => {
        setShow(true);
    }

    const handleShowSeedModal = () => {
        setShow(true);
    }

    const handlePinConfirmChange = (pin) => {
        if (/^\d*$/.test(pin) && pin.length <= 6) {
          setPinConfirm(pin);
          setError('');
        } else {
          setError('PIN confirmation must be a number and have at most 6 digits.');
        }
      };
    
    const handleConfirmClick = () => {
        if (pin.length === 6 && pinConfirm.length === 6) {
          if (pin === pinConfirm) {
            CreatePin();
            setError('');
          } else {
            setError('PIN and PIN confirmation do not match. Please enter again.');
          }
        } else {
          setError('Please enter a 6-digit PIN and PIN confirmation.');
        }
    };

    const CreatePin = async()  => {
        const data = {
            pin : pin,
            pinconfirm: pinConfirm
        };
        try { 
            setLoadingSend(true);
            const response = await CreatePincode(data, token, result_telegramWebApp);
            if(response.data.isSuccess === true){
                setLoadingSend(false);
                const newUserData = {
                    ...user,
                    pincode: response.data.data.pincode
                };
                setUser(newUserData);
                setPin('');
                setPinConfirm('');
            }
        } catch (error) {
            // console.error('Error:', error);
        }
    }

    const handleConfirmShowSeed = () => {
        if (pin.length === 6 ) {
            handleShowSeed();
        }
        else {
          setError('Please enter a 6-digit PIN and PIN confirmation.');
        }
    };

    const handleShowSeed = async()  => {
        const data = {
            currentpincode : pin
        };
        try { 
            setLoadingSend(true);
            const response = await ShowSeedPharse(data, token, result_telegramWebApp);
            if(response.status.code === 200){
                setError('');
                setSeedPharse(response.data.seedPharse);
                setLoadingSend(false);
                setShow(false);
                setPin('');
                setShowModal(true);
            } else{
                setError(response.status.message);
                setLoadingSend(false);
            }
            
        } catch (error) {
            // console.error('Error:', error);
        }
    }

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied successfully');
        } catch (err) {
            toast.warning('Copy failed');
        }
    };

    // function getHEXAddress(string) {
    //     const address = new TonWeb.utils.Address(string.toString());
    //     return address.toString(false, true, true);
    // }

    useEffect(() => {
        const fetchData = async () => {

            // no change
            const TonApiKey = process.env.REACT_APP_TON_API_KEY;

            const addressJetton = process.env.REACT_APP_ADDRESS_JETTON;

            const addressBalanceToken = user.nonBounceableAddress;

            const config_addressBalance = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/accounts/${addressBalanceToken}/jettons?currencies=usd`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}` 
                }
            };

            try {
                const response = await axios.request(config_addressBalance);
                const data = typeof response.data == 'undefined' ? null : response.data;
                //console.log('data', data);
                if (data != null) {
                    const getDataBalance = data.balances;
                    const address =  new window.TonWeb.utils.Address(addressJetton);
                    const findBalanceTPLANT = getDataBalance.find(x => x.jetton.address == address.toString(false, true, true))
                    const result = typeof findBalanceTPLANT == 'undefined' ? 0 : findBalanceTPLANT.balance / 10 ** 9
                    setBalanceTPLANT(result);
                } else{
                }
            } catch (error) {
                // console.error('Error fetching data:', error);
            }

        };


        // Call API initially
        fetchData();

        // Call API every 10 seconds
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);
    return (
        <React.Fragment>
            <div className="wallet-wrap-item">
                <h4 className="mb-3 d-block text-start">Wallet Address</h4>
                <div className="wallet-item-adress">
                    <div className="wallet-item-text">
                        <span className="text-white hexAddress"><span>{user && user.nonBounceableAddress.slice(0, maxLength) + '...'}</span>
                        <CopyToClipboard text={user && user.nonBounceableAddress}
                            onCopy={() => toast.success('Copied successfully')}>
                            <FiCopy size={20} title="Copy!" className="ms-2 copy text-warning" />
                            </CopyToClipboard>
                        </span>
                    </div>
                    {
                        user && !user.pincode ?
                        <button className="btn pixel-btn mt-0" onClick={handleShowModal}>Seed Pharse</button>
                        :
                        <button className="btn pixel-btn mt-0" onClick={handleShowSeedModal}>Seed Pharse</button>
                    }
                </div>
                <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        <img src={TCoin_Icon} alt="" />
                        <span>TPLANT (On-chain)</span>
                    </div>
                    <span>{balanceTPLANT ? balanceTPLANT : 0}</span>
                </div>
                <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        <img src={TON_Icon} alt="" />
                        <span>TON</span>
                    </div>
                    <span>{tonAccountBalance ? tonAccountBalance : 0}</span>
                </div>
            </div>
            <div className="pincode-wrap-item">

            </div>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                className="main-app-modal pincode-app"
            >
                {
                    loadingSend &&
                    <div className="loading-wrap">
                        <div className="loading-send">
                            <img src={Loss_Icon} alt="" />
                            <div className="popup-grey-text">Please wait a few seconds for your<br /> to be processed..</div>
                        </div>
                    </div>
                }
                <Modal.Header closeButton>
                    {
                        user && !user.pincode ?
                        <Modal.Title>SET PIN CODE</Modal.Title>
                        :
                        <Modal.Title>PIN CODE</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body className="text-center">
                    {
                        user && !user.pincode ?
                        <span className="d-block mb-2">Can't change, Remember!!!</span>
                        :
                        ""
                    }
                    <div className="form-group">
                        <label htmlFor="otpInput">Pin code:</label>
                        <OtpInput
                        id="otpInput"
                        containerStyle="PIN-wrap mt-3 mb-3"
                        value={pin}
                        onChange={handleOTPChange}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle="form-control text-primary"
                        inputType="number"
                        />
                    </div>
                    {
                        user && !user.pincode ?
                        <div className="form-group">
                            <label htmlFor="pinConfirmInput">Confirm pin code:</label>
                            <OtpInput
                            id="pinConfirmInput"
                            containerStyle="PIN-wrap mt-3 mb-3"
                            value={pinConfirm}
                            onChange={handlePinConfirmChange}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle="form-control text-primary"
                            inputType="number"
                            />
                        </div>
                        :
                        ""
                    }
                    
                    <div className="error-message text-danger">{error}</div>
                    {
                        user && !user.pincode ?
                        <button className="btn pixel-btn btn-show-key mt-3" onClick={handleConfirmClick}>Confirm </button>
                        :
                        <button className="btn pixel-btn btn-show-key mt-3" onClick={handleConfirmShowSeed}>Confirm </button>
                    }
                    
                </Modal.Body>
            </Modal>
            <div className={!showModal ? "private_key_wrap" : "private_key_wrap active"}>
                <FiX className="close_detail" size={32} onClick={() => {setShowModal(false); setShowPrivateKey(false)}} />
                <div className="modal-title h4 text-start">Seed Pharse</div>
                <hr/>
                <ul className={!showPrivateKey ? "private_key_list" : "private_key_list active"}>
                    {
                        seedPharse && seedPharse.map((item,index) => {
                            return(
                                <li key={index}>{index+1}. {item}</li>
                            )
                        })
                    }
                    <div className="load-pixel">
                        <img src={Pixel_Icon} alt="" />
                        <img src={Pixel_Icon} alt="" />
                        <img src={Pixel_Icon} alt="" />
                        <img src={Pixel_Icon} alt="" />
                    </div>
                </ul>
                {
                    !showPrivateKey ? 
                    <button className="btn pixel-btn btn-show-key mt-3" onClick={handleShowKey}>Show Seed Pharse</button>
                    :
                    <button className="btn pixel-btn btn-show-key mt-3" onClick={() => copyToClipBoard(seedPharse && seedPharse.join(' '))} ><FiCopy size={20} title="Copy!" className="me-2 copy text-dark d-inline-block" />Copy Seed Pharse</button>
                }
                <div className="detail_list active mt-2 mb-2">
                    
                    <h6 className="d-block text-start mb-2">Export Wallet</h6>
                    <span className="d-block text-start mb-2">To use the wallet, follow these steps:</span>
                    <ul>
                        <li>1. Copy your Seed Pharse</li>
                        <li>2. Import your seed into Ton Wallet, TonHub, Tonkeeper, ...</li>
                    </ul>
                </div>
                <Alert variant='danger'>
                    Never share these 24 words with anyone, we are not responsible for displaying the seed phrase in the telegram application, please keep the seed phrase information very secure.
                </Alert>               
                
            </div>
        </React.Fragment>
    );
}

export default WalletAppItemWrap;
