import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Unity, useUnityContext, RegisterExternalListener } from "react-unity-webgl";

import axios from 'axios';

import moment from 'moment';
import 'moment-timezone';
import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Logo from '../../assets/images/Logo.png';
import Ton_Icon from '../../assets/images/icons/ton_symbol.svg';
import TCoin_Icon from '../../assets/images/icons/FCoin.png';
import TDuck_Icon from '../../assets/images/icons/TDUCK.png';
import FCoin_Icon from '../../assets/images/icons/FCoin_t.png';
import Usdt_Icon from '../../assets/images/icons/usdt_icon.png';

import Pagination from 'react-bootstrap/Pagination';

import QuestAppWrap from './quest/quest';
import WalletAppWrap from './wallet/wallet';
import ConvertWebAppWrap from './convert/convertWebApp';
import MarketAppWrap from './markets';
import StakingWebApp from './staking';
import { useUser } from '../../context/UserContext';

import {
    GetListReferral
} from '../../api/index';


const PlayGameMobile = ({ jsonString }) => {

    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user");

    const { user, setUser } = useUser();

    // exchange rate Ton to Tplant

    const [exchangeTplantUsdt, setExchangeTplantUsdt] = useState(0);
    const [exchangeTplantTon, setExchangeTplantTon] = useState(0);
    const [tonAccountBalance, setTonAccountBalance] = useState(0);


    const {
        unityProvider, requestFullscreen, isLoaded, loadingProgression,
        addEventListener, removeEventListener, sendMessage
    } = useUnityContext({
        loaderUrl: `${process.env.PUBLIC_URL}/playGame/build/Build.loader.js`,
        dataUrl: `${process.env.PUBLIC_URL}/playGame/build/webgl.data`,
        frameworkUrl: `${process.env.PUBLIC_URL}/playGame/build/build.framework.js`,
        codeUrl: `${process.env.PUBLIC_URL}/playGame/build/build.wasm`,
        // streamingAssetsUrl: "streamingassets",
        // webGLContextAttributes: {
        //     alpha: true,
        //     antialias: true,
        //     depth: true,
        //     failIfMajorPerformanceCaveat: true,
        //     powerPreference: "high-performance",
        //     premultipliedAlpha: true,
        //     preserveDrawingBuffer: true,
        //     stencil: true,
        //     desynchronized: true,
        //     xrCompatible: true,
        // },
    });

    const loadingPercentage = Math.round(loadingProgression * 100);
    const unityRef = useRef();

    const refreshPage = () => {
        window.location.reload(false);
    }

    /// From React to Unity
    //  gameObjectName: GameManager,
    //  methodName: MessageFromjavaScript,
    //  parameter: message
    function handleLoadFirstData(isLoaded) {
        if (isLoaded) {
            if (jsonString != null) {
                sendMessage("GameManager", "MessageFromjavaScript", jsonString);
            }
        }
    }

    // From Unity To React
    const returnUnityToReact = useCallback((message) => {
        if (message === 'reloadPage') {
            refreshPage();
        }
    }, []);

    useEffect(() => {
        addEventListener("gamePlay", returnUnityToReact);
        return () => {
            removeEventListener("gamePlay", returnUnityToReact);
        };
    }, [addEventListener, removeEventListener, returnUnityToReact]);

    useEffect(() => {
        handleLoadFirstData(isLoaded);
    }, [isLoaded]);

    useEffect(() => {
        const fetchData = async () => {
            const pool = process.env.REACT_APP_POOL;
            const TonApiKey = process.env.REACT_APP_TON_API_KEY;

            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/blockchain/accounts/${pool}/methods/get_reserves`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}` 
                }
            };
            try {
                const response = await axios.request(config);
                const data = response.data;
                if (data) {
                    const reserve0 = data.decoded.reserve0;
                    const reserve1 = data.decoded.reserve1;
                    const rate = reserve0 / reserve1;
                    setExchangeTplantTon(rate);
                }
            } catch (error) {
                
            }

            const addressBalance = user ? user.nonBounceableAddress : "";
            const config_addressBalance = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/blockchain/accounts/${addressBalance}`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}` 
                }
            };

            try {
                const response = await axios.request(config_addressBalance);
                const data = typeof response.data == 'undefined' ? null : response.data;
                if (data != null) {
                    const balance = data.balance / 10 ** 9;
                    // console.log(balance);
                    const newUserData = {
                        ...user,
                        tonAccountBalance: balance
                    };
                    setUser(newUserData);
                    setTonAccountBalance(balance);
                } else{
                    setTonAccountBalance(0)
                }
            } catch (error) {
            }

            const config_addressBalanceUsdt = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/rates?tokens=${process.env.REACT_APP_MASTER_ADDRESS}&currencies=usd`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}` 
                }
            };

            try {
                const response = await axios.request(config_addressBalanceUsdt);
                const data = typeof response.data == 'undefined' ? null : response.data;
                if (data != null) {
                    const ratesArray = Object.entries(data.rates);
                    setExchangeTplantUsdt(ratesArray[0][1].prices.USD);
                } else{
                    setExchangeTplantUsdt(0)
                }
            } catch (error) {
                
            }
        };

        // Call API initially
        fetchData();

        // Call API every 10 seconds
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            
            <div ref={unityRef} className='text-center mainApp-wrap'>
                <div className="mainApp-header d-flex mb-3">
                    <div className='mainApp-header-left'>
                        <div className='mainApp-userName'>
                            {user ? user.username : ""}
                        </div>
                        {/* <div className='mainApp-exchange'>
                            <div className='mainApp-exchange-item'>
                                <div className='mainApp-exchange-item-left'>
                                    <img src={TCoin_Icon} alt="" /> - <img src={Ton_Icon} alt="" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='mainApp-header-right'>
                        <a className="btn pixel-btn mt-0" href="https://t.me/tfarm_exchange" target="_blank">Join Group </a>
                    </div>
                </div>
                <div className='mainApp-wrap-container-wrap'>
                    <Tabs
                        defaultActiveKey="Home"
                        className="mb-3"
                        variant="pills"
                    >
                        <Tab eventKey="Home" title={<>Home</>}>
                            <div className="home-mainApp-list-coin mainApp-list-coin">
                                {
                                    exchangeTplantTon > 0 &&
                                    <div className="coin-item-wrap d-flex align-items-center justify-content-center gap-1 rate-item">
                                        <div className="d-flex align-items-center justify-content-between gap-1">
                                            <span className='d-flex align-items-center justify-content-center gap-1'><img src={TCoin_Icon} alt="" /> 1 TPLANT</span>
                                        </div>
                                        <span>= </span>
                                        <span className='d-flex align-items-center justify-content-center gap-1'>{exchangeTplantTon.toFixed(8)} TON <img src={Ton_Icon} alt="" /></span>
                                    </div>
                                }
                                {
                                    exchangeTplantUsdt > 0 &&
                                    <div className="coin-item-wrap d-flex align-items-center justify-content-center gap-1 rate-item">
                                        <div className="d-flex align-items-center justify-content-between gap-1">
                                            <span className='d-flex align-items-center justify-content-center gap-1'><img src={TCoin_Icon} alt="" /> 1 TPLANT</span>
                                        </div>
                                        <span>= </span>
                                        <span className='d-flex align-items-center justify-content-center gap-1'>{exchangeTplantUsdt.toFixed(8)} USDT <img src={Usdt_Icon} alt="" /></span>
                                    </div>
                                }
                                <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <img src={Ton_Icon} alt="" />
                                        <span>TON</span>
                                    </div>
                                    <span>{tonAccountBalance ? parseFloat(tonAccountBalance).toFixed(5) : 0}</span>
                                </div>
                                <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <img src={FCoin_Icon} alt="" />
                                        <span>TFARM</span>
                                    </div>
                                    <span>{user ? parseFloat(user.TFARM_Balance).toFixed(2) : 0}</span>
                                </div>
                                <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <img src={TCoin_Icon} alt="" />
                                        <span>TPLANT (In-Game)</span>
                                    </div>
                                    <span>{user ? parseFloat(user.TPLANT_Balance).toFixed(2) : 0}</span>
                                </div>
                                <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <img src={TCoin_Icon} alt="" />
                                        <span>TPLANT (Accumulative)</span>
                                    </div>
                                    <span>{user ? parseFloat(user.TPLANT_Balance_Accumulate).toFixed(2) : 0}</span>
                                </div>
                                <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <img src={TDuck_Icon} alt="" />
                                        <span>TDUCK</span>
                                    </div>
                                    <span>{user ? parseFloat(user.TDUCK_Balance).toFixed(5) : 0}</span>
                                </div>
                            </div>
                        </Tab>
                        {/* <Tab eventKey="Draw" title={<>Draw</>}></Tab> */}
                        <Tab eventKey="Game" title={<>Game</>}>
                            {!isLoaded &&
                                <div className='loading'>
                                    <h3>Loading... ({loadingPercentage}%)</h3>
                                </div>
                            }
                            <div className='mainApp-wrap-container'>
                                <Unity
                                    unityProvider={unityProvider}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        visibility: isLoaded ? "visible" : "hidden"
                                    }}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="Quest" title={<>Quest</>}><QuestAppWrap jsonString={jsonString} /></Tab>
                        <Tab eventKey="Convert" title={<>Convert</>}><ConvertWebAppWrap jsonString={jsonString} exchangeTplantTon={exchangeTplantTon} tonAccountBalance={tonAccountBalance} /></Tab>
                        <Tab eventKey="Wallet" title={<>Wallet</>}><WalletAppWrap jsonString={jsonString} tonAccountBalance={tonAccountBalance} /></Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
};
const MainWebApp = ({ jsonString }) => {
    return (
        <PlayGameMobile jsonString={jsonString} />
    );
};

export default MainWebApp;