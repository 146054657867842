import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TelegramLoginButton from 'react-telegram-login';
import { useToast } from 'rc-toastr';

import { useMediaQuery } from 'react-responsive';

import Modal from 'react-bootstrap/Modal';

import Logo from '../assets/images/Logo.png';
import MenuIcon from '../assets/images/icons/Menu.svg';
import MenuClose from '../assets/images/icons/close.svg';

import { FaTelegramPlane, FaDiscord } from "react-icons/fa";

import { useUser } from '../context/UserContext';
import { setAuthToken } from "../helpers/setAuthToken";


import {
    RegisterUser,
    GetInfo
} from '../api/index';

import Ton_Icon from '../assets/images/icons/ton_symbol.svg';
import TCoin_Icon from '../assets/images/icons/FCoin.png';
import FCoin_Icon from '../assets/images/icons/FCoin_t.png';
import User_Avatar from '../assets/images/icons/user.png';

function shortenString(inputString, startLength, endLength) {
    if (inputString.length <= startLength + endLength) {
        // Nếu chuỗi ngắn hơn hoặc bằng tổng độ dài muốn giữ, không cần cắt
        return inputString;
    }
    // Lấy các ký tự từ đầu đến startLength và từ (endLength * -1) đến cuối
    const shortenedString = inputString.slice(0, startLength) + '...' + inputString.slice(-endLength);

    return shortenedString;
}

const HeaderPage = (props) => {

    const isDesktop = useMediaQuery({ minWidth: 768 });
    const { user, setUser } = useUser();
    const [show, setShow] = useState(false);
    const { toast } = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const shouldDisplaySocialButtons = currentPath === '/dashboard';

    const [collapsed, setCollapsed] = useState(true);

    const handleShowMenu = () => {
        setCollapsed(false)
    }

    const handleClose = () => {
        setShow(false);
    };

    const handleConfirmLogout = () => {
        setShow(true);
    }

    const handleSubmitLogout = () => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('userPlay');
        setShow(false);
        navigate('/');
    }

    // const getInfoUser = async(token) => {
    //     const responseInfo = await GetInfo("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoX2RhdGUiOjE3MDU4NDYxMzQsImlkIjo4NjA3NDg0NjAsImhhc2giOiJmZDlmOWVhZmY2ODhiN2VmNWE3Zjk3NDM3NjUwZjYxMzdmZGUxMjFmN2Y3NmEwNTBiODg5OWVkYmM1MDczZGVkIiwibGFzdF9uYW1lIjoifCIsImZpcnN0X25hbWUiOiJIYXJyeSBOZ3V5ZW4iLCJpYXQiOjE3MDU4NDYxMzYsImV4cCI6MTcwNTg4NjEzNn0.7k1VpsDYleJ-sn1tSnOK1c-H6KxloSCtlCabjrWTkjE");
    //     localStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoX2RhdGUiOjE3MDU4NDYxMzQsImlkIjo4NjA3NDg0NjAsImhhc2giOiJmZDlmOWVhZmY2ODhiN2VmNWE3Zjk3NDM3NjUwZjYxMzdmZGUxMjFmN2Y3NmEwNTBiODg5OWVkYmM1MDczZGVkIiwibGFzdF9uYW1lIjoifCIsImZpcnN0X25hbWUiOiJIYXJyeSBOZ3V5ZW4iLCJpYXQiOjE3MDU4NDYxMzYsImV4cCI6MTcwNTg4NjEzNn0.7k1VpsDYleJ-sn1tSnOK1c-H6KxloSCtlCabjrWTkjE");
    //     const userData = {
    //         id: responseInfo.id,
    //         username: responseInfo.lastname + " " + responseInfo.firstname,
    //         email: responseInfo.email,
    //         photo: responseInfo.photo,
    //         hexAddress: responseInfo.hexAddress,
    //         bounceableAddress: responseInfo.bounceableAddress,
    //         createdAtTimestamp: responseInfo.createdAtTimestamp,
    //         TFARM_Balance: responseInfo.TFARM_Balance,
    //         TON_Balance: responseInfo.TON_Balance,
    //         TPLANT_Balance: responseInfo.TPLANT_Balance,
    //         isActived: responseInfo.isActived,
    //     }
    //     setUser(userData);
    //     localStorage.setItem('user', JSON.stringify(userData));
    //     console.log(responseInfo);
    // }

    const handleTelegramResponse = async (response) => {
        //console.log(response);
        setUser(response);
        if (response) {
            const loginPayload = {
                telegramId: response.id,
                auth_date: response.auth_date,
                first_name: response.first_name,
                last_name: response.last_name,
                photo_url: response.photo_url ? response.photo_url : "",
                hash: response.hash,
                username: response.username,
                platform: 'TELEGRAM'
            };
            const register = await RegisterUser(loginPayload);
            if (register.data.data) {
                const token = register.data.data.token;
                localStorage.setItem("token", token);
                localStorage.setItem("id", response.id);

                let userPlay = {
                    id: response.id,
                    token: token,
                    first_name: response.first_name,
                    last_name: response.last_name,
                }
                localStorage.setItem("userPlay", JSON.stringify(userPlay));

                //set token to axios common header
                setAuthToken(token);
                if (token) {
                    const responseInfo = await GetInfo(token);
                    const userData = {
                        id: responseInfo.id,
                        username: responseInfo.lastname + " " + responseInfo.firstname,
                        email: responseInfo.email,
                        photo: responseInfo.photo,
                        hexAddress: responseInfo.hexAddress,
                        bounceableAddress: responseInfo.bounceableAddress,
                        createdAtTimestamp: responseInfo.createdAtTimestamp,
                        TFARM_Balance: responseInfo.TFARM_Balance,
                        TON_Balance: responseInfo.TON_Balance,
                        TPLANT_Balance: responseInfo.TPLANT_Balance,
                        isActived: responseInfo.isActived,
                    }
                    setUser(userData);
                    localStorage.setItem('user', JSON.stringify(userData));
                    //console.log(responseInfo);
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 2000);
                }
            } else {
                toast.warning('A connection error has occurred. Please check again.');
            }
            if (register.data.isSuccess === false || register.data.status.code === 400 || register.data.status.code === 369) {
                toast.warning(`${register.data.status.message}. Please check again.`);
            }
        } else {
        }
    };

    return (
        <>
            <header className="">
                {/* container start */}
                <div className="container">
                    {/* navigation bar */}
                    <nav className="navbar navbar-expand-lg">
                        <Link className="navbar-brand d-block d-sm-none" to="/">
                            <img src={Logo} alt="Logo" />
                        </Link>
                        <button
                            className="navbar-mobile btn d-block d-sm-none"
                            type="button"
                            onClick={handleShowMenu}
                        >
                            <img src={MenuIcon} alt="" />
                        </button>
                        <div className="" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link className="navbar-brand" to="/">
                                        <img src={Logo} alt="Logo" />
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/dashboard">
                                        OnBoard
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://docs.tfarm.io" target="_blank">
                                        Docs
                                    </a>
                                </li>
                            </ul>
                            {/* <ul className="navbar-right">
                                {
                                    user ?
                                        <>
                                            {
                                                shouldDisplaySocialButtons &&
                                                <li className="nav-item nav-item-coin">
                                                    <div className="d-flex coin-item">
                                                        <img src={Ton_Icon} alt="" />
                                                        <span>{user.TON_Balance ? parseFloat(user.TON_Balance).toFixed(5) : 0}</span>
                                                    </div>
                                                    <div className="d-flex coin-item">
                                                        <img src={TCoin_Icon} alt="" />
                                                        <span>{user.TPLANT_Balance ? parseFloat(user.TPLANT_Balance).toFixed(5) : 0}</span>
                                                    </div>
                                                    <div className="d-flex coin-item">
                                                        <img src={FCoin_Icon} alt="" />
                                                        <span>{user.TFARM_Balance ? parseFloat(user.TFARM_Balance).toFixed(5) : 0}</span>
                                                    </div>
                                                </li>
                                            }
                                            <li className="nav-item nav-item-user">
                                                <div className="user-item">
                                                    {
                                                        user.photo_url ?
                                                            <img src={user.photo_url} alt="" />
                                                            :
                                                            <img src={User_Avatar} alt="" />
                                                    }
                                                    <div className="user-name">
                                                        <span className="fw-bolder">{user.bounceableAddress ? shortenString(user.bounceableAddress, 4, 4) : ""}</span>
                                                        <span>{user.username ? user.username : ""}</span>
                                                    </div>
                                                </div>
                                                <div className="logout-wrap">
                                                    <button className="btn pixel-btn" onClick={handleConfirmLogout}>
                                                        <FaTelegramPlane />
                                                        <span>Logout</span>
                                                    </button>
                                                </div>
                                            </li>
                                        </>
                                        :
                                        ""
                                }
                                {
                                    !user ?
                                        <>
                                            {
                                                isDesktop ?
                                                    <>
                                                        <li className="nav-item">
                                                            <TelegramLoginButton
                                                                dataOnauth={handleTelegramResponse}
                                                                botName="tfarmtestnetBot"
                                                                requestAccess="write"
                                                            />
                                                        </li>
                                                    </>
                                                    :
                                                    ""
                                            }
                                        </>
                                        :
                                        ""
                                }
                            </ul> */}
                        </div>
                    </nav>
                    {/* navigation end */}
                </div>
                {/* container end */}
            </header>
            <div className={collapsed ? 'menu_mobile' : 'menu_mobile active'}>
                <div className="colse_mb" onClick={() => setCollapsed(true)}>
                    <img src={MenuClose} alt="" />
                </div>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            OnBoard
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://docs.tfarm.io" target="_blank">
                            Docs
                        </a>
                    </li>
                    {
                        !user ?
                            <>
                                {
                                    !isDesktop ?
                                        <>
                                            <li className="nav-item">
                                                <TelegramLoginButton
                                                    dataOnauth={handleTelegramResponse}
                                                    botName="tfarmpixelBot"
                                                    requestAccess="write"
                                                />
                                            </li>
                                        </>
                                        :
                                        ""
                                }
                            </>
                            :
                            ""
                    }
                </ul>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>TFARM</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">Are you sure you want to log out?</Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button className="btn btn-primary px-4" onClick={handleSubmitLogout}>
                        <div className="d-flex align-items-center">
                            Yes
                        </div>
                    </button>
                    <button type="button" className="btn btn-danger px-4 ms-2 text-white" onClick={handleClose}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default HeaderPage;
