import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

import { FiArrowDown } from "react-icons/fi";

import moment from 'moment';
import 'moment-timezone';
import {
    ConvertTPLANT,
    GetHistoryConvertTPLANT
} from '../../../api/index';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import { useUser } from '../../../context/UserContext';

import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';
import TCoin_Icon from '../../../assets/images/icons/FCoin.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';


const ConvertTplantOnChainWebApp = () => {

    const token = localStorage.getItem("token");
    const tonPrice = localStorage.getItem("tonPrice");
    const { user, setUser } = useUser();

    const ratio = 0.001;

    const [balanceTPLANT, setBalanceTPLANT] = useState(0);
    const [amountTplant, setAmountTo] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const [estimatedTime, setEstimatedTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [convertMessage, setConvertMessage] = useState('');
    const [convertMessageErr, setConvertMessageErr] = useState('');

    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleInputChange = (event) => {
        let value = event.target.value;

        // Loại bỏ mọi ký tự không phải số hoặc dấu chấm thập phân từ giá trị
        value = value.replace(/[^0-9.]/g, '');

        // Đảm bảo chỉ có một dấu chấm thập phân được nhập vào
        const decimalCount = (value.match(/\./g) || []).length;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }

        const numericValue = Number(value);
        // Kiểm tra giá trị và đặt thông báo lỗi nếu cần
        if (numericValue < 100) {
            setErrorMessage('The value must be greater than or equal to 100.');
        } else {
            setErrorMessage('');
        }

        // Cập nhật giá trị vào state
        setAmountTo(value);
    };

    const handleMax = () => {
        setAmountTo(balanceTPLANT);
    }

    const handleEstimatedTime = () => {
        const currentTime = moment();
        // Cộng thêm 20 giây
        const estimatedTime = currentTime.add(20, 'seconds');
        // Format thời gian theo định dạng mong muốn
        const formattedTime = estimatedTime.format('D/M/YYYY, HH:mm:ss');
        // Gán thời gian vào state
        setEstimatedTime(formattedTime);
    }

    const handleConvert = async () => {
        setLoadingSend(true);
        const data = {
            amountFrom: amountTplant
        };
        handleEstimatedTime();

        const responseConvert = await ConvertTPLANT(data, token);
        //console.log(responseConvert);
        if (responseConvert) {
            if (responseConvert.status.code === 400) {
                setConvertMessageErr(responseConvert.status.message);
                setConvertMessage('');
                setEstimatedTime('');
            }
            if (responseConvert.status === 200) {
                setConvertMessage(responseConvert.data.status.message);
                setConvertMessageErr("");
                setAmountTo(0);
                setTimeout(() => {
                    GetHistoryConvert();
                }, 2000)
            }
            setLoadingSend(false);
        }
    }

    const GetHistoryConvert = async () => {
        if (token) {
            try {
                const responseList = await GetHistoryConvertTPLANT(token);
                if (responseList.status.code !== 440) {
                    setSelectedList(responseList.data);
                } else {
                    setSelectedList([]);
                }
            } catch (error) {
                // console.error(error.message);
            }
        }
    };

    useEffect(() => {
        GetHistoryConvert();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

            // no change
            const TonApiKey = process.env.REACT_APP_TON_API_KEY;

            const addressJetton = process.env.REACT_APP_ADDRESS_JETTON;

            const addressBalanceToken = user ? user.nonBounceableAddress : "";

            const config_addressBalance = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/accounts/${addressBalanceToken}/jettons?currencies=usd`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}` 
                }
            };

            try {
                const response = await axios.request(config_addressBalance);
                const data = typeof response.data == 'undefined' ? null : response.data;
                if (data != null) {
                    const getDataBalance = data.balances;
                    const address =  new window.TonWeb.utils.Address(addressJetton);
                    const findBalanceTPLANT = getDataBalance.find(x => x.jetton.address == address.toString(false, true, true));
                    const result = typeof findBalanceTPLANT == 'undefined' ? 0 : findBalanceTPLANT.balance / 10 ** 9;
                    setBalanceTPLANT(result);
                } else{
                }
            } catch (error) {
                // console.error('Error fetching data:', error);
            }

        };

        // Call API initially
        fetchData();

        // Call API every 10 seconds
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <React.Fragment>
            <div className="position-relative">
                {
                    loadingSend &&
                    <div className="loading-wrap">
                        <div className="loading-send">
                            <img src={Loss_Icon} alt="" />
                            <h3>Convert TPLANT</h3>
                            <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                        </div>
                    </div>
                }
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="">
                            <div className="convertBoxTop">
                                <h2 className="convertBoxTitle">Convert TPLANT</h2>
                                <div className="convertBoxIn d-flex">
                                    <div className="convertBoxIn_Left">
                                        <h3 className="text-start">Input TPLANT amount</h3>
                                        <input
                                            id="token-amout-input"
                                            className="input-group"
                                            placeholder="0.000"
                                            value={amountTplant}
                                            onChange={handleInputChange}
                                        />
                                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                    </div>
                                    <div className="convertBoxIn_Right">
                                        <div className="coinWrap d-flex align-items-center">
                                            <img src={TCoin_Icon} alt="" />
                                            <span>TPLANT (On-Chain)</span>
                                        </div>
                                        <div className="availableBox">
                                            <p className="">Available: <span>{balanceTPLANT ? balanceTPLANT : 0}</span></p>
                                            <p className="text-primary" onClick={handleMax}>MAX</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="convertBoxIcon">
                                    <div className="icon">
                                        <FiArrowDown />
                                    </div>
                                </div>
                                <div className="convertBoxOut d-flex">
                                    <div className="convertBoxIn_Left">
                                        <h3 className="text-start">You'll Receive</h3>
                                        <input id="token-amout-output" disabled autoComplete="false" value={amountTplant} placeholder="0.000" className="input-group" />
                                    </div>
                                    <div className="convertBoxIn_Right">
                                        <div className="coinWrap d-flex align-items-center">
                                            <img src={TCoin_Icon} alt="" />
                                            <span>TPLANT (In-Game)</span>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-3">You will convert from on-chain.</h3>
                                {
                                    estimatedTime && <h3 className="mb-0">Estimate wait time: {estimatedTime}</h3>
                                }
                                {
                                    convertMessage && <h3 className="mb-0 text-success text-message">{convertMessage}</h3>
                                }
                                {
                                    convertMessageErr && <h3 className="mb-0 text-danger text-message">{convertMessageErr}</h3>
                                }
                                <button className="btn pixel-btn" onClick={handleConvert} disabled={!amountTplant}>Request Convert</button>
                            </div>
                            <div className="convertBoxBottom">
                                <h2 className="convertBoxTitle">Convert History</h2>
                                <table className='mt-3 list-referral-wrap mb-3' style={{width: '100%'}}>
                                        <thead style={{textAlign: 'center'}}>
                                            <tr>
                                                <td>convertId</td>
                                                <td>AmountConvert</td>
                                                <td>Status</td>
                                            </tr>
                                        </thead>
                                        <tbody style={{textAlign: 'center'}}>
                                            {
                                                currentItems.length ?
                                                    currentItems.map((item, index) => {
                                                        return (
                                                            <tr className="text-center" key={index}>
                                                                <td>
                                                                    <span className="fw-bolder">{item.convertId}</span>
                                                                </td>
                                                                <td>
                                                                    <span className="fw-bolder">{item.amountConvert}</span>
                                                                </td>
                                                                <td>
                                                                    <span className="fw-bolder">{item.status}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div className="text-center">
                                                                <span className="d-block text-center text-warning mt-3 mb-3 fs-4">No data.</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        selectedList.length > 0 && (
                                            <Pagination className="justify-content-end">
                                                <Pagination.Prev
                                                    onClick={() =>
                                                        setCurrentPage((prevPage) =>
                                                            prevPage > 1 ? prevPage - 1 : prevPage
                                                        )
                                                    }
                                                    disabled={prevDisabled}
                                                >
                                                    <FiChevronsLeft />
                                                </Pagination.Prev>
                                                {pageNumbers
                                                    .slice(currentPage - 1, currentPage + 4) // Giới hạn số lượng mục
                                                    .map((number) => (
                                                        <Pagination.Item
                                                            key={number}
                                                            active={number === currentPage}
                                                            onClick={() => handlePageChange(number)}
                                                        >
                                                            {number}
                                                        </Pagination.Item>
                                                    ))}
                                                <Pagination.Next
                                                    onClick={() =>
                                                        setCurrentPage((prevPage) =>
                                                            prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                                        )
                                                    }
                                                    disabled={nextDisabled}
                                                >
                                                    <FiChevronsRight />
                                                </Pagination.Next>
                                            </Pagination>
                                        )
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ConvertTplantOnChainWebApp;
