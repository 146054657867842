import React, { useState, useEffect, useRef } from "react";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import QuestAction from "./questAction";
import QuestDaily from "./questDaily";

import Carot_Icon from '../../../assets/images/icons/Carrot.png';
import Logo from '../../../assets/images/Logo.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';

import {
    ClaimQuest,
    GetQuestInfo,
    GetInfo,
    UpdateUsernameX
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';


const QuestAppWrap = () => {
    const user_webapp = localStorage.getItem("user_webapp");

    const token = localStorage.getItem("token");
    const { toast } = useToast();
    const { user, setUser } = useUser();

    
    const [usernameOfX, setUsernameOfX] = useState(null);
    const [convertMessage, setConvertMessage] = useState('');
    const [convertMessageErr, setConvertMessageErr] = useState('');

    const [questInfo, setQuestInfo] = useState(null);
    const [loadingSend, setLoadingSend] = useState(false);
    const [messageErr, setMessageErr] = useState('');
    
    const handleSetUsernameOfX = (event) => {
        let value = event.target.value;
        setUsernameOfX(value);
    };

    const handleUpdateUsernameOfX = async () => {
        const data = {
            usernameOfX: usernameOfX
        };

        const responseConvert = await UpdateUsernameX(data, token);
        console.log(responseConvert);
        if (responseConvert) {
            if (responseConvert.status.code === 400) {
                setConvertMessageErr(responseConvert.status.message);
                setConvertMessage('');
            }
            if (responseConvert.status === 200) {
                setConvertMessage(responseConvert.data.status.message);
                setConvertMessageErr("");
            }
        }
    };

    const handleClaimQuest = async () => {
        setLoadingSend(true);
        const responseClaimQuest = await ClaimQuest(token);
        if (responseClaimQuest) {
            if (responseClaimQuest.status.code === 400) {
                setMessageErr(responseClaimQuest.status.message);
                setTimeout(() => {
                    setMessageErr('');
                }, 3000)
            }
            if (responseClaimQuest.status.code === 200) {
                await loadQuestInfo();
                setMessageErr('Claim Success');
                setTimeout(() => {
                    setMessageErr('');
                }, 3000);

            }
            setLoadingSend(false);
        }
    }

    const loadQuestInfo = async () => {
        if (token) {
            const responseQuestInfo = await GetQuestInfo(token);
            if (responseQuestInfo.status.code === 200) {
                setQuestInfo(responseQuestInfo.data);
            } else {
                setQuestInfo(null);
            }
        }
    }

    useEffect(() => {

        loadQuestInfo();

    }, []);

    return (
        <React.Fragment>
            <>
                {
                    loadingSend &&
                    <div className="loading-wrap">
                        <div className="loading-send">
                            <img src={Loss_Icon} alt="" />
                            <h3>Claim TPLANT</h3>
                            <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                        </div>
                    </div>
                }
                <div className="quest-wrap pt-3">
                    <div className="quest-container gap-2">
                        <div className="text-center">
                            <h3>Do daily tasks to earn extra quest points, creating more value for your crop</h3>
                            <div className="reward-points-top">
                                <img src={Carot_Icon} alt="" />
                                <h3>{questInfo ? questInfo.questPoint : <Skeleton />}</h3>
                                {
                                    questInfo ?
                                        <>
                                            {
                                                questInfo.questPoint > 0 ? <button className="btn pixel-btn mb-2" onClick={handleClaimQuest}>Claim</button> : ""
                                            }
                                        </>
                                        :
                                        ""
                                }
                                {
                                    messageErr && <h3 className="mb-2 text-danger text-message">{messageErr}</h3>
                                }
                            </div>
                            {
                                questInfo ?
                                    <>
                                        {
                                            !questInfo.x_username ? 
                                            <div className="tw-wrap-username">
                                                <input
                                                    id="tw-username"
                                                    className="input-group text-center"
                                                    type="text"
                                                    onChange={handleSetUsernameOfX}
                                                    value={usernameOfX}
                                                />
                                                <span className="text-danger">Please enter the correct username of X</span>
                                                <button className="btn pixel-btn btn-dark" onClick={handleUpdateUsernameOfX} disabled={!usernameOfX}>Update Username Of X</button>
                                                
                                            </div> 
                                            : 
                                            ""
                                        }
                                    </>
                                    :
                                    ""
                            }
                            {
                                convertMessage && <h3 className="mb-0 text-success text-message">{convertMessage}</h3>
                            }
                            {
                                convertMessageErr && <h3 className="mb-0 text-danger text-message">{convertMessageErr}</h3>
                            }
                            <Tabs
                                defaultActiveKey="DailyQuest"
                                className="mb-3"
                                variant="pills"
                            >
                                <Tab eventKey="DailyQuest" title={<>Daily Quest</>}>
                                    <QuestDaily questInfo={questInfo} loadQuestInfo={loadQuestInfo} />
                                </Tab>
                                <Tab eventKey="ActionQuest" title={<>Action Quest</>}>
                                    <QuestAction questInfo={questInfo} loadQuestInfo={loadQuestInfo} />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </>
        </React.Fragment>
    );
}

export default QuestAppWrap;
