import React, { useState, useEffect, useRef } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import moment from 'moment';
import 'moment-timezone';
import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';

import {
    ClaimQuest,
    GetQuestInfo,
    GetListReferral
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';

import {
    FiCopy,
    FiChevronRight,
    FiCornerUpRight
} from "react-icons/fi";

import WalletAppItemWrap from "./walletItem";


const WalletAppWrap = ({tonAccountBalance}) => {

    const maxLength = 45;

    const user_webapp = localStorage.getItem("user_webapp");

    const token = localStorage.getItem("token");
    const { toast } = useToast();
    const { user, setUser } = useUser();

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };


    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied successfully');
        } catch (err) {
            toast.warning('Copy failed');
        }
    };

    const shareButton = () => {
        let text = `TFarm offers you a golden opportunity for growth!\n🌟Let's harvest together and reap the rewards we deserve!🌾💰`
        window.location.href = `https://t.me/share/url?url=https://t.me/tfarmpixelBot?start=${user.id}&text=${text}`;
    }

    useEffect(() => {
        
        if(token){
            const GetListReferralData = async () => {
                try {
                    const responseList = await GetListReferral(token);
                    if (responseList.status.code !== 440) {
                        setSelectedList(responseList.data);
                    } else {
                        setSelectedList([]);
                    }
                } catch (error) {
                    // console.error(error.message);
                }
            };
            GetListReferralData();
        }
    }, []);

    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wallet-wrap pt-3">
                            <div className="wallet-container gap-2">
                                <div className="wallet-item">
                                    <div onClick={() => setShow(true)} className="text-white d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <span>TON Wallet</span>
                                        <FiChevronRight />
                                    </div>
                                    <div className="wallet-item-text">
                                        <span className="text-white hexAddress">
                                            <span>{user && user.nonBounceableAddress.slice(0, maxLength) + '...'}</span>
                                            <CopyToClipboard text={user && user.nonBounceableAddress}
                                                onCopy={() => toast.success('Copied successfully')}>
                                                <FiCopy size={20} title="Copy!" className="ms-2 copy text-warning" />
                                            </CopyToClipboard>
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="wallet-item mt-3">
                                    <div className="text-white d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <span>TON Wallet</span>
                                        <span>Coming Soon</span>
                                    </div>
                                    <div className="wallet-item-text">
                                        <span className="text-white hexAddress"><span>{user && user.nonBounceableAddress}</span><FiCopy size={20} title="Copy!" className="ms-2 copy text-warning"  onClick={() => copyToClipBoard(user && user.bounceableAddress)} /></span>
                                    </div>
                                </div> */}
                                <div className="invite_code mt-3">
                                    <span className="d-block text-left invite_code_title">Invite Code</span>
                                    <div className="invite_code_container d-flex align-items-center justify-content-between gap-2">
                                        <div className="left d-flex align-items-center">
                                            <span className="text-white hexAddress d-flex align-items-center"><span className="invite_code_text">{user && user.id}</span>
                                                <CopyToClipboard text={`https://t.me/tfarmpixelBot?start=${user ? user.id : ""}`}
                                                    onCopy={() => toast.success('Copied successfully')}>
                                                    <FiCopy size={20} title="Copy!" className="ms-2 copy text-warning" />
                                                </CopyToClipboard>
                                            </span>
                                        </div>
                                        <div className="right">
                                            <button className="btn pixel-btn btn-checkin mt-0" onClick={() => shareButton()}><FiCornerUpRight /> Send</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="invite_list mt-3">
                                    <h6 className='mt-3 mb-2 text-start'>Team</h6>
                                    <table className='mt-3 list-referral-wrap mb-3' style={{width: '100%'}}>
                                        <thead style={{textAlign: 'center'}}>
                                            <tr>
                                                <td style={{width: '30%'}}>Fullname</td>
                                                <td>Created At</td>
                                            </tr>
                                        </thead>
                                        <tbody style={{textAlign: 'center'}}>
                                            {
                                                currentItems.length ?
                                                    currentItems.map((item, index) => {
                                                        return (
                                                            <tr className="text-center" key={index}>
                                                                <td>
                                                                    <span className="fw-bolder">{item.fullname}</span>
                                                                </td>
                                                                <td><span className="fw-bolder fs-small">{moment.unix(parseInt(item.createdAtTimestamp)).tz("UTC").format("MM/DD/YYYY HH:mm:ss")}</span></td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <div className="text-center">
                                                                <span className="d-block text-center text-warning mt-3 mb-3 fs-4">No data.</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        selectedList.length > 0 && (
                                            <Pagination className="justify-content-end">
                                                <Pagination.Prev
                                                    onClick={() =>
                                                        setCurrentPage((prevPage) =>
                                                            prevPage > 1 ? prevPage - 1 : prevPage
                                                        )
                                                    }
                                                    disabled={prevDisabled}
                                                >
                                                    <FiChevronsLeft />
                                                </Pagination.Prev>
                                                {pageNumbers
                                                    .slice(currentPage - 1, currentPage + 4) // Giới hạn số lượng mục
                                                    .map((number) => (
                                                        <Pagination.Item
                                                            key={number}
                                                            active={number === currentPage}
                                                            onClick={() => handlePageChange(number)}
                                                        >
                                                            {number}
                                                        </Pagination.Item>
                                                    ))}
                                                <Pagination.Next
                                                    onClick={() =>
                                                        setCurrentPage((prevPage) =>
                                                            prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                                        )
                                                    }
                                                    disabled={nextDisabled}
                                                >
                                                    <FiChevronsRight />
                                                </Pagination.Next>
                                            </Pagination>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    className="main-app-modal modal-bg-dark"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Wallet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <WalletAppItemWrap tonAccountBalance={tonAccountBalance} />
                    </Modal.Body>
                </Modal>
            </div>

        </React.Fragment>
    );
}

export default WalletAppWrap;
