import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import Logo from '../../../assets/images/Logo.png';
import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';
import Coper_Icon from '../../../assets/images/icons/bronze.png';
import Silver_Icon from '../../../assets/images/icons/silver.png';
import Gold_Icon from '../../../assets/images/icons/gold.png';
import Diamond_Icon from '../../../assets/images/icons/diamond.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';
import { FiArrowDownCircle } from "react-icons/fi";


import {
    TopUpPackage,
} from '../../../api/index';

const PackageListWrap = ({ jsonString }) => {
    const [show, setShow] = useState(false);
    const [packageName, setPackageName] = useState('');
    const [loadingSend, setLoadingSend] = useState(false);
    const [convertMessage, setConvertMessage] = useState('');
    const [convertMessageErr, setConvertMessageErr] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (package_name) => {
        setShow(true);
        setPackageName(package_name);
    };

    const refreshPage = () => {
        window.location.reload(false);
    }

    const dataJson = JSON.parse(jsonString);

    const handleBuyPackage = async (package_name) => {
        setShow(false);
        setLoadingSend(true);
        const data = {
            package: package_name,
        };

        const responseConvert = await TopUpPackage(data, dataJson.token);
        if (responseConvert) {
            if (responseConvert.status.code === 400) {
                setConvertMessageErr(responseConvert.status.message);
                setConvertMessage('');
            }
            if (responseConvert.status === 200) {
                setConvertMessage(responseConvert.data.status.message);
                setConvertMessageErr("");
            }
            setLoadingSend(false);
        }
    }


    return (
        <>
            <div className='text-center commission-wrap'>
                {
                    loadingSend &&
                    <div className="loading-wrap">
                        <div className="loading-send">
                            <img src={Loss_Icon} alt="" />
                            <h3>Buy Commission</h3>
                            <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                        </div>
                    </div>
                }
                <div className='text-center commission-container'>
                    <div className='commission-top'>
                        <h2>Commission Information</h2>
                        <div className='d-flex align-items-center justify-content-center gap-2 mb-2'>
                            {/* <Link to="/referral" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Commission Info</Link>        */}
                            <Link to="/history" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >History</Link>
                            <Link to="/main" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Home</Link>
                        </div>
                        <div className='list-commission-info justify-content-center align-items-center'>
                            {
                                convertMessage ? <span className="text-success">{convertMessage}</span> : ""
                            }
                            {
                                convertMessageErr ? <span className="text-danger">{convertMessageErr}</span> : ""
                            }
                            {/* <div className='commission-info-item copper-item'>
                                <img src={Coper_Icon} alt="" className="img-fluid" width="24" height="24" />
                                <span className='title'>BRONZE</span>
                                <span className='number'>100 <img src={Ton_Icon} alt="" className="img-fluid" width="16" height="16" /></span>
                                <button className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2"  onClick={() => handleShow('BRONZE')}>Top-up</button>
                            </div>
                            <div className='commission-info-item silver-item'>
                                <img src={Silver_Icon} alt="" className="img-fluid" width="24" height="24" />
                                <span className='title'>SILVER</span>
                                <span className='number'>500 <img src={Ton_Icon} alt="" className="img-fluid" width="16" height="16" /></span>
                                <button className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" onClick={() => handleShow('SILVER')}>Top-up</button>
                            </div>
                            <div className='commission-info-item gold-item'>
                                <img src={Gold_Icon} alt="" className="img-fluid" width="24" height="24" />
                                <span className='title'>GOLD</span>
                                <span className='number'>1000 <img src={Ton_Icon} alt="" className="img-fluid" width="16" height="16" /></span>
                                <button className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" onClick={() => handleShow('GOLD')}>Top-up</button>
                            </div>
                            <div className='commission-info-item diamond-item'>
                                <img src={Diamond_Icon} alt="" className="img-fluid" width="24" height="24" />
                                <span className='title'>DIAMOND</span>
                                <span className='number'>2000 <img src={Ton_Icon} alt="" className="img-fluid" width="16" height="16" /></span>
                                <button className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" onClick={() => handleShow('DIAMOND')}>Top-up</button>
                            </div> */}
                            <div className='commission-info-item copper-item w-50 mx-auto p-4'>
                                <span className='title'>F1</span>
                                <span className='number'>8%</span>
                            </div>
                            <div className='d-flex align-items-center justify-content-center'>
                                <FiArrowDownCircle size={24} className='text-primary arrow-down' />
                            </div>
                            <div className='commission-info-item silver-item mt-0 w-50 mx-auto p-4'>
                                <span className='title'>F2</span>
                                <span className='number'>3%</span>
                            </div>
                            <div className='d-flex align-items-center justify-content-center'>
                                <FiArrowDownCircle size={24} className='text-primary arrow-down' />
                            </div>
                            <div className='commission-info-item gold-item mt-0 w-50 mx-auto p-4'>
                                <span className='title'>F3</span>
                                <span className='number'>2%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='text-center'>Are you sure you want to buy this package?</Modal.Body>
                <Modal.Footer className='justify-content-center'>
                <button className='btn pixel-btn btn-secondary text-dark' onClick={handleClose}>
                    Close
                </button>
                <button className='btn pixel-btn btn-primary text-dark' onClick={() => handleBuyPackage(packageName)}>
                    Yes
                </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
const PackageList = ({ jsonString }) => {
    return (
        <PackageListWrap jsonString={jsonString} />
    );
};

export default PackageList;