import React, { useState, useEffect, useRef } from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import moment from 'moment';
import 'moment-timezone';
import {
    CheckJoinTelegramChannel,
    CheckJoinTelegramGroup,
    CheckConvertTfarm
} from '../../../api/index';

import { FiCheck } from "react-icons/fi";
import { FaTelegram } from "react-icons/fa";


import { useUser } from '../../../context/UserContext';

import Carot_Icon from '../../../assets/images/icons/Carrot.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';

const QuestAction = ({ questInfo, loadQuestInfo }) => {

    const token = localStorage.getItem("token");
    const { user, setUser } = useUser();

    const [checkIsJoinTelegramGroup, setCheckIsJoinTelegramGroup] = useState(false);
    const [checkIsJoinTelegramChannel, setCheckIsJoinTelegramChannel] = useState(false);
    const [checkIsJoinTelegramChannelMessage, setCheckIsJoinTelegramChannelMessage] = useState(null);
    const [checkIsJoinTelegramGroupMessage, setCheckIsJoinTelegramGroupMessage] = useState(null);
    const [loadingSend, setLoadingSend] = useState(false);

    const handleCheckInChannel = async () => {
        setLoadingSend(true);
        const responseJoinTelegramChannel = await CheckJoinTelegramChannel(token);
        if (responseJoinTelegramChannel) {
            if (responseJoinTelegramChannel.status.code === 400) {
                setCheckIsJoinTelegramChannel(false);
                setCheckIsJoinTelegramChannelMessage(`You haven't joined the channel yet.`)
                setTimeout(() => {
                    setCheckIsJoinTelegramChannelMessage(null);
                }, 2500)
            }
            if (responseJoinTelegramChannel.status.code === 200) {
                if (responseJoinTelegramChannel.data.isJoinTelegramChannel === false) {
                    setCheckIsJoinTelegramChannel(false);
                    setCheckIsJoinTelegramChannelMessage(`You haven't joined the channel yet.`)
                    setTimeout(() => {
                        setCheckIsJoinTelegramChannelMessage(null);
                    }, 2500);
                }

                if (responseJoinTelegramChannel.data.isJoinTelegramChannel === true) {
                    setCheckIsJoinTelegramChannel(true);
                    loadQuestInfo();
                }

            }
            setLoadingSend(false);
        }
    }

    const handleCheckInGroup = async () => {
        setLoadingSend(true);
        const responseJoinTelegramGroup = await CheckJoinTelegramGroup(token);
        if (responseJoinTelegramGroup) {
            if (responseJoinTelegramGroup.status.code === 400) {
                setCheckIsJoinTelegramGroup(false);
                setCheckIsJoinTelegramGroupMessage(`You haven't joined the group yet.`)
                setTimeout(() => {
                    setCheckIsJoinTelegramGroupMessage(null);
                }, 2500)
            }
            if (responseJoinTelegramGroup.status.code === 200) {

                if (responseJoinTelegramGroup.data.isJoinTelegramGroup === false) {
                    setCheckIsJoinTelegramGroup(false);
                    setCheckIsJoinTelegramGroupMessage(`You haven't joined the group yet.`)
                    setTimeout(() => {
                        setCheckIsJoinTelegramGroupMessage(null);
                    }, 2500);
                }

                if (responseJoinTelegramGroup.data.isJoinTelegramGroup === true) {
                    setCheckIsJoinTelegramGroup(true);
                    loadQuestInfo();
                }

            }
            setLoadingSend(false);
        }
    }

    const loadingQuestInfo = () => {
        if (questInfo != null) {
            setCheckIsJoinTelegramGroup(questInfo.isJoinTelegramGroup)
            setCheckIsJoinTelegramChannel(questInfo.isJoinTelegramChannel)
        }
    }

    useEffect(() => {
        loadingQuestInfo();
    }, [questInfo]);

    const CheckConvertTfarmData = async () => {
        if (token) {
            try {
                const responseList = await CheckConvertTfarm(token);
            } catch (error) {
                // console.error(error.message);
            }
        }
    };

    useEffect(() => {
        CheckConvertTfarmData();
    }, []);

    


    return (
        <React.Fragment>
            <section className="daily_section">
                <div className="container position-relative">
                    {
                        loadingSend &&
                        <div className="loading-wrap">
                            <div className="loading-send">
                                <img src={Loss_Icon} alt="" />
                                <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                            </div>
                        </div>
                    }
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="convertBox pixel-box">
                                <div className="convertBoxTop">
                                    <div className="d-flex align-items-start gap-2">
                                        <div className="quest-box-left text-left">
                                            <span className="quest-title">Join the TFarm Channel</span>
                                            <span className="text-start font-sm">Enter the Telegram channel and unite with other players to receive bonus points!</span>
                                        </div>
                                        <div className="quest-box-right">
                                            <span>1000</span>
                                            <img src={Carot_Icon} alt="" />
                                        </div>
                                    </div>
                                    <div className="join-channel">
                                        <FaTelegram />
                                        <div className="join-channel-title">
                                            <span>TFarm Channel</span>
                                            <span>@tfarm_community</span>
                                        </div>
                                        <a className="btn pixel-btn" href="https://t.me/tfarm_community" target="_blank">Go</a>
                                    </div>
                                    {
                                        questInfo != null ?
                                            <>
                                                {
                                                    checkIsJoinTelegramChannel ?
                                                        <span className="check-icon"><FiCheck /></span>
                                                        :
                                                        <button className="btn pixel-btn btn-checkin mt-0" onClick={handleCheckInChannel}>Check</button>
                                                }
                                            </>
                                            :
                                            <Skeleton />
                                    }
                                    {
                                        checkIsJoinTelegramChannelMessage != null ?
                                            <span className="text-start font-sm text-danger text-message">{checkIsJoinTelegramChannelMessage}</span>
                                            :
                                            ""
                                    }
                                    {/* {
                                        checkIsJoinTelegramChannelMessage ?
                                            <span className="text-start font-sm text-success text-message">You have joined the channel yet!</span>
                                            :
                                            ""
                                    } */}
                                </div>
                            </div>
                            <div className="convertBox pixel-box">
                                <div className="convertBoxTop">
                                    <div className="d-flex align-items-start gap-2">
                                        <div className="quest-box-left text-left">
                                            <span className="quest-title">Join the TFarm Group</span>
                                            <span className="text-start font-sm">Enter the Telegram group and unite with other players to receive bonus points!</span>
                                        </div>
                                        <div className="quest-box-right">
                                            <span>1000</span>
                                            <img src={Carot_Icon} alt="" />
                                        </div>
                                    </div>
                                    <div className="join-channel">
                                        <FaTelegram />
                                        <div className="join-channel-title">
                                            <span>TFarm Group</span>
                                            <span>@tfarm_exchange</span>
                                        </div>
                                        <a className="btn pixel-btn" href="https://t.me/tfarm_exchange" target="_blank">Go</a>
                                    </div>
                                    {
                                        questInfo != null ?
                                            <>
                                                {
                                                    checkIsJoinTelegramGroup ?
                                                        <span className="check-icon"><FiCheck /></span>
                                                        :
                                                        <button className="btn pixel-btn btn-checkin mt-0" onClick={handleCheckInGroup}>Check</button>
                                                }
                                            </>
                                            :
                                            <Skeleton />
                                    }
                                    {
                                        checkIsJoinTelegramGroupMessage != null ?
                                            <span className="text-start font-sm text-danger text-message">{checkIsJoinTelegramGroupMessage}</span>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className="convertBox pixel-box">
                                <div className="convertBoxTop">
                                    <div className="d-flex align-items-start gap-2">
                                        <div className="quest-box-left text-left">
                                            <span className="quest-title">Total Convert</span>
                                            <span className="text-start font-sm">Enter the Telegram group and unite with other players to receive bonus points!</span>
                                        </div>
                                        <div className="quest-box-right">
                                            <span>1000</span>
                                            <img src={Carot_Icon} alt="" />
                                        </div>
                                    </div>
                                    <div className="join-channel p-3">
                                        <div className="join-channel-title">
                                            <span className="d-flex align-items-center gap-1"><b className="font-md">1000 / 10000</b> <img src={Carot_Icon} alt="" /></span>
                                        </div>
                                    </div>
                                    {
                                        questInfo != null ?
                                            <>
                                                {
                                                    checkIsJoinTelegramGroup ?
                                                        <span className="check-icon"><FiCheck /></span>
                                                        :
                                                        <button className="btn pixel-btn btn-checkin mt-0" onClick={handleCheckInGroup}>Check</button>
                                                }
                                            </>
                                            :
                                            <Skeleton />
                                    }
                                    {
                                        checkIsJoinTelegramGroupMessage != null ?
                                            <span className="text-start font-sm text-danger text-message">{checkIsJoinTelegramGroupMessage}</span>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default QuestAction;
