import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

import { FiArrowDown } from "react-icons/fi";

import moment from 'moment';
import 'moment-timezone';
import {
    GetInfo,
    TransferTFARM,
    GetHistoryTransferTFARM
} from '../../../api/index';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import { useUser } from '../../../context/UserContext';

import FCoin_Icon from '../../../assets/images/icons/FCoin_t.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';


const TransferTfarm = ({userInfo}) => {

    const token = localStorage.getItem("token");
    const { user, setUser } = useUser();

    const [amountTfarm, setAmountTfarm] = useState(0);
    const [idUser, setIdUser] = useState();

    const [estimatedTime, setEstimatedTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [convertMessage, setConvertMessage] = useState('');
    const [convertMessageErr, setConvertMessageErr] = useState('');

    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleInputChange = (event) => {
        let value = event.target.value;
        // Loại bỏ mọi ký tự không phải số hoặc dấu chấm thập phân từ giá trị
        value = value.replace(/[^0-9.]/g, '');
        // Đảm bảo chỉ có một dấu chấm thập phân được nhập vào
        const decimalCount = (value.match(/\./g) || []).length;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }
        // Cập nhật giá trị vào state
        setAmountTfarm(value)
    };

    const handleInputChangeIdUser = (event) => {
        let value = event.target.value;
        setIdUser(value);
    };

    const handleMax = () => {
        setAmountTfarm(user.TFARM_Balance);
    }

    const handleEstimatedTime = () => {
        const currentTime = moment();
        // Cộng thêm 20 giây
        const estimatedTime = currentTime.add(20, 'seconds');
        // Format thời gian theo định dạng mong muốn
        const formattedTime = estimatedTime.format('D/M/YYYY, HH:mm:ss');
        // Gán thời gian vào state
        setEstimatedTime(formattedTime);
    }

    const handleConvert =  async () => {
        setLoadingSend(true);
        const data = {
            toId: idUser,
            amountTFARM: amountTfarm
        };
        handleEstimatedTime();

        const responseConvert = await TransferTFARM(data,token);
        //console.log(responseConvert);
        if(responseConvert) {
            if(responseConvert.status.code === 400){
                setConvertMessageErr(responseConvert.status.message);
                setConvertMessage('');
                setEstimatedTime('');
            }
            if(responseConvert.status === 200) {
                setConvertMessage(responseConvert.data.status.message);
                setConvertMessageErr("");
                setIdUser();
                setAmountTfarm(0);
                setTimeout(() => {
                    window.location.reload();
                },2000)
            }
            setLoadingSend(false);
        }
    }

    useEffect(() => {
        const fetchTonPrice = async () => {
            try {
                const responseList = await GetHistoryTransferTFARM(token);
                if( responseList.status.code !== 440){
                    setSelectedList(responseList.data);
                } else{
                    setSelectedList([]);
                }
            } catch (error) {
                // console.error(error.message);
            }
        };
        fetchTonPrice();
    }, []);

    return (
        <React.Fragment>
            <section className="convert_section">
                <div className='d-flex align-items-center justify-content-center'>
                    <Link to="/convert" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Home</Link>
                </div>
                <div className="container position-relative">
                    {
                        loadingSend && 
                        <div className="loading-wrap">
                            <div className="loading-send">
                                <img src={Loss_Icon} alt="" />
                                <h3>Transfer Tfarm</h3>
                                <div className="popup-grey-text">Please wait a few seconds for your<br/>transaction to be processed..</div>
                            </div>
                        </div>
                    }
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="convertBox pixel-box">
                                <div className="convertBoxTop">
                                    <h2 className="convertBoxTitle">Transfer Tfarm</h2>
                                    <div className="convertBoxOut d-flex mb-3">
                                        <div className="convertBoxIn_Left">
                                            <h3>ID User</h3>
                                            <input 
                                                autoComplete="false" 
                                                placeholder="EG: TELEGRAM ID" 
                                                className="input-group" 
                                                value={idUser}
                                                onChange={handleInputChangeIdUser}
                                            />
                                        </div>
                                    </div>
                                    <div className="convertBoxIn d-flex">
                                        <div className="convertBoxIn_Left">
                                            <h3>Input Tfarm amount</h3>
                                            <input
                                                id="token-amout-input"
                                                className="input-group"
                                                placeholder="0.000"
                                                value={amountTfarm}
                                                onChange={handleInputChange}
                                            />
                                            <p className="number">${amountTfarm}</p>
                                        </div>
                                        <div className="convertBoxIn_Right">
                                            <div className="coinWrap d-flex align-items-center">
                                                <img src={FCoin_Icon} alt="" />
                                                <span>TFARM</span>
                                            </div>
                                            <div className="availableBox">
                                                <p className="">Available: <span>{user && user.TFARM_Balance}</span></p>
                                                <p className="text-primary" onClick={handleMax}>MAX</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <h3 className="mt-3">You will transfer from on-chain.</h3>
                                    {
                                        estimatedTime && <h3 className="mb-0">Estimate wait time: {estimatedTime}</h3>
                                    }
                                    {
                                        convertMessage && <h3 className="mb-0 text-success text-message">{convertMessage}</h3>
                                    }
                                    {
                                        convertMessageErr && <h3 className="mb-0 text-danger text-message">{convertMessageErr}</h3>
                                    }
                                    <button className="btn pixel-btn" disabled={!amountTfarm && !idUser} onClick={handleConvert}>Request Transfer</button>
                                </div>
                                <div className="convertBoxBottom">
                                    <h2 className="convertBoxTitle">Transfer History</h2>
                                    <Table responsive className="table-hover table-bordered">
                                        <thead>
                                            <tr className="text-center">
                                                <th>Txid</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Sender ID</th>
                                                <th>Receiver ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentItems.length ?
                                                currentItems.map((item,index) => {
                                                    return(
                                                        <tr className="text-center" key={index}>
                                                            <td><span>{item.randomId.slice(0, 4) + "..." + item.randomId.slice(-4)}</span></td>
                                                            <td><span className="text-primary fw-bolder">{moment.unix(parseInt(item.createdAtTimestamp)).tz("UTC").format("MM/DD/YYYY HH:mm:ss")}</span></td>
                                                            <td>
                                                                <span className="fw-bolder">{item.amount} <img src={FCoin_Icon} alt="" width={16} /></span>
                                                            </td>
                                                            <td><span>{item.senderId}</span></td>
                                                            <td><span>{item.receiverId}</span></td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className="text-center">
                                                            <span className="d-block text-center text-warning mt-3 fs-3">No data.</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    {
                                        selectedList.length ?
                                        <Pagination className="justify-content-end">
                                            <Pagination.Prev
                                                onClick={() =>
                                                    setCurrentPage((prevPage) =>
                                                        prevPage > 1 ? prevPage - 1 : prevPage
                                                    )
                                                }
                                                disabled={prevDisabled}
                                            >
                                                <FiChevronsLeft />
                                            </Pagination.Prev>
                                            {pageNumbers.map((number) => (
                                                <Pagination.Item
                                                    key={number}
                                                    active={number === currentPage}
                                                    onClick={() => handlePageChange(number)}
                                                >
                                                    {number}
                                                </Pagination.Item>
                                            ))}
                                            <Pagination.Next
                                                onClick={() =>
                                                    setCurrentPage((prevPage) =>
                                                    prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                                    )
                                                }
                                                disabled={nextDisabled}
                                            >
                                                <FiChevronsRight />
                                            </Pagination.Next>
                                        </Pagination>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default TransferTfarm;
