import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

import { FiArrowDown } from "react-icons/fi";

import moment from 'moment';
import 'moment-timezone';
import {
    GetHistoryWithdrawTPLANT,
    WithdrawTPLANT
} from '../../../api/index';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import { useUser } from '../../../context/UserContext';

import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';
import TCoin_Icon from '../../../assets/images/icons/FCoin.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';


const WithDrawTplantWebApp = ({ exchangeTplantTon }) => {
 
    const token = localStorage.getItem("token");
    const tonPrice = localStorage.getItem("tonPrice");
    const { user, setUser } = useUser();

    const [amountTPLANT, setAmountTPLANT] = useState(0);

    const [estimatedTime, setEstimatedTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [convertMessage, setConvertMessage] = useState('');
    const [convertMessageErr, setConvertMessageErr] = useState('');

    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleInputChange = (event) => {
        let value = event.target.value;

        // Loại bỏ mọi ký tự không phải số hoặc dấu chấm thập phân từ giá trị
        value = value.replace(/[^0-9.]/g, '');

        // Đảm bảo chỉ có một dấu chấm thập phân được nhập vào
        const decimalCount = (value.match(/\./g) || []).length;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }

        // Cập nhật giá trị vào state
        setAmountTPLANT(value);
    };

    const handleMax = () => {
        setAmountTPLANT(user.TON_Balance);
    }

    const handleEstimatedTime = () => {
        const currentTime = moment();
        // Cộng thêm 20 giây
        const estimatedTime = currentTime.add(20, 'seconds');
        // Format thời gian theo định dạng mong muốn
        const formattedTime = estimatedTime.format('D/M/YYYY, HH:mm:ss');
        // Gán thời gian vào state
        setEstimatedTime(formattedTime);
    }




    const handleConvert = async () => {
        setLoadingSend(true);
        const data = {
            amountFrom: amountTPLANT
        };
        handleEstimatedTime();

        const responseConvert = await WithdrawTPLANT(data, token);
        //console.log(responseConvert);
        if (responseConvert) {
            if (responseConvert.status.code === 400) {
                setConvertMessageErr(responseConvert.status.message);
                setConvertMessage('');
                setEstimatedTime('');
            }
            if (responseConvert.status === 200) {
                setConvertMessage(responseConvert.data.status.message);
                setConvertMessageErr("");
                setAmountTPLANT(0);
                setTimeout(() => {
                    GetHistoryConvert();
                }, 2000)
            }
            setLoadingSend(false);
        }        
    }

    const GetHistoryConvert = async () => {
        if (token) {
            try {
                setLoading(true);
                const responseList = await GetHistoryWithdrawTPLANT(token);
                if (responseList.status.code !== 440) {
                    setSelectedList(responseList.data);
                } else {
                    setSelectedList([]);
                }
            } catch (error) {
                // console.error(error.message);
            }
        }
    };

    useEffect(() => {
        GetHistoryConvert();
    }, []);

    return (
        <React.Fragment>
            <div className="position-relative">
                {
                    loadingSend &&
                    <div className="loading-wrap">
                        <div className="loading-send">
                            <img src={Loss_Icon} alt="" />
                            <h3>Withdraw TPLANT</h3>
                            <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                        </div>
                    </div>
                }
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="">
                            <div className="convertBoxTop">
                                <h2 className="convertBoxTitle text-start">Withdraw TPLANT Accumulate</h2>
                                <div className="convertBoxIn d-flex">
                                    <div className="convertBoxIn_Left">
                                        <h3 className="text-start">Input TPLANT amount</h3>
                                        <div className="position-relative">
                                            <input
                                                id="token-amout-input"
                                                className="input-group"
                                                placeholder="0.000"
                                                value={amountTPLANT}
                                                onChange={handleInputChange}
                                            />
                                            <img src={TCoin_Icon} alt="" className="tplant-icon-absolute" />
                                        </div>
                                        <span className="text-primary d-block text-start">Fee: 3% + 50 TPLANT</span>
                                    </div>
                                    {/* <div className="convertBoxIn_Right">
                                        <div className="availableBox">
                                            <p className="">Available: <span>{user && user.TON_Balance}</span></p>
                                            <p className="text-primary" onClick={handleMax}>MAX</p>
                                        </div>
                                    </div> */}
                                </div>
                                <h3 className="mt-3">You will convert from on-chain.</h3>
                                {
                                    estimatedTime && <h3 className="mb-0">Estimate wait time: {estimatedTime}</h3>
                                }
                                {
                                    convertMessage && <h3 className="mb-0 text-success text-message">{convertMessage}</h3>
                                }
                                {
                                    convertMessageErr && <h3 className="mb-0 text-danger text-message">{convertMessageErr}</h3>
                                }
                                <button className="btn pixel-btn" onClick={handleConvert} disabled={!amountTPLANT}>Request Withdraw</button>                                
                            </div>
                            <div className="convertBoxBottom">
                                <h2 className="convertBoxTitle">Withdraw History</h2>
                                <Table responsive className="table-hover table-bordered">
                                    <thead>
                                        <tr className="text-center">
                                            <th>Date</th>
                                            <th>FeeConvert</th>
                                            <th>TotalAmountConvert</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentItems.length ?
                                                currentItems.map((item, index) => {
                                                    return (
                                                        <tr className="text-center" key={index}>
                                                            <td><span className="text-primary fw-bolder">{moment.unix(parseInt(item.createdAtTimestamp)).tz("UTC").format("MM/DD/YYYY HH:mm:ss")}</span></td>
                                                            <td>
                                                                <span className="fw-bolder">{item.feeConvert} <img src={TCoin_Icon} alt="" width={16} /></span>
                                                            </td>
                                                            <td>
                                                                <span className="fw-bolder">{item.totalAmountConvert} <img src={TCoin_Icon} alt="" width={16} /></span>
                                                            </td>
                                                            <td>
                                                                <span className="fw-bolder">{item.status}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className="text-center">
                                                            <span className="d-block text-center text-warning mt-3 fs-3">No data.</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                                {
                                    selectedList.length > 0 && (
                                        <Pagination className="justify-content-end">
                                            <Pagination.Prev
                                                onClick={() =>
                                                    setCurrentPage((prevPage) =>
                                                        prevPage > 1 ? prevPage - 1 : prevPage
                                                    )
                                                }
                                                disabled={prevDisabled}
                                            >
                                                <FiChevronsLeft />
                                            </Pagination.Prev>
                                            {pageNumbers
                                                .slice(currentPage - 1, currentPage + 4) // Giới hạn số lượng mục
                                                .map((number) => (
                                                    <Pagination.Item
                                                        key={number}
                                                        active={number === currentPage}
                                                        onClick={() => handlePageChange(number)}
                                                    >
                                                        {number}
                                                    </Pagination.Item>
                                                ))}
                                            <Pagination.Next
                                                onClick={() =>
                                                    setCurrentPage((prevPage) =>
                                                        prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                                    )
                                                }
                                                disabled={nextDisabled}
                                            >
                                                <FiChevronsRight />
                                            </Pagination.Next>
                                        </Pagination>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default WithDrawTplantWebApp;
