import axios from 'axios';

// host api
const host = "https://api-test.tfarm.io";
//const host = "http://localhost:5002";
// list api
const urlLoginWebApp = host + '/v1/user/loginWebApp';
const urlRegister = host + '/v1/user/loginTelegram';
const urlGetInfo = host + '/v1/user/getUserInfor';
const urlConvertTONtoTFARM = host + '/v1/wallet/convertTONtoTFARM';
const urlGetTONPrice = host + '/v1/wallet/rate';
const urlGetHistoryConvertTONtoTFARM = host + '/v1/wallet/getHistoryConvertTONtoTFARM';
const urlTransferTFARM = host + '/v1/wallet/transferTFARM';
const urlGetHistoryTransferTFARM = host + '/v1/wallet/getHistoryTransferTFARM';
const urlConvertTPLANTtoTON = host + '/v1/wallet/convertTPLANTtoTON';
const urlGetHistoryConvertTPLANTtoTON = host + '/v1/wallet/getHistoryConvertTPLANTtoTON';
//webapp
const urlTopUpPackage = host + '/v1/wallet/topUpPackage';
const urlGetHistoryTopUp = host + '/v1/wallet/getHistoryTopUp';
const urlGetListReferral = host + '/v1/user/getListReferral';
const urlGetIsMembership = host + '/v1/user/getIsMembership';
const urlTopUpMembership = host + '/v1/wallet/topUpMembership';
const urlGetHistoryCommissionTopup = host + '/v1/com/getHistoryCommissionTopup';
//quest

const urlCheckInDaily = host + '/v1/quest/checkInDaily';
const urlIsCheckInDaily = host + '/v1/quest/isCheckInDaily';
const urlGetQuestInfo = host + '/v1/quest/getQuestInfo';
const urlClaimQuest = host + '/v1/quest/claimQuest';
const urlCheckJoinTelegramChannel = host + '/v1/quest/checkJoinTelegramChannel';
const urlCheckJoinTelegramGroup = host + '/v1/quest/checkJoinTelegramGroup';
const urlUpdateUsernameX = host + '/v1/quest/updateUsernameX';
const urlCheckConvertTfarm = host + '/v1/quest/checkConvertTfarm';

//pincode webapp
const urlCreatePincode = host + '/v1/user/createPincode';
const urlShowSeedPharse = host + '/v1/user/showSeedPharse';

//withdraw
const urlWithdrawTPLANT = host + '/v1/wallet/withdrawTPLANT';
const urlGetHistoryWithdrawTPLANT = host + '/v1/wallet/getHistoryWithdrawTPLANT';
const urlConvertTPLANT = host + '/v1/wallet/convertTPLANT';
const urlGetHistoryConvertTPLANT = host + '/v1/wallet/getHistoryConvertTPLANT';
const urlGetAccumulatedTPLANT = host + '/v1/wallet/getAccumulatedTPLANT';
const urlUpdateToAccumulatedTPLANT = host + '/v1/wallet/updateToAccumulatedTPLANT';

const LoginWebApp = async (loginPayload) => {
    try {
        var common_axios = axios.create({
            baseURL: urlLoginWebApp
        });
        const response = await common_axios.post(urlLoginWebApp, loginPayload);
        return response;
    } catch (error) {
        console.log('Error:', error);
        return error.response;
    }
}

const TopUpPackage = async (data, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlTopUpPackage
        });
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlTopUpPackage, data);
        return response;
    } catch (error) {
        console.log('Error:', error);
        return error.response.data;
    }
};

const GetHistoryTopUp = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetHistoryTopUp
        });
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetHistoryTopUp);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const GetListReferral = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetListReferral
        });
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetListReferral);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const GetIsMembership = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetIsMembership
        });
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetIsMembership);
        return response;
    } catch (error) {
        console.log('Error:', error);
        return error.response.data;
    }
};

const TopUpMembership = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlTopUpMembership
        });
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlTopUpMembership);
        return response;
    } catch (error) {
        console.log('Error:', error);
        return error.response.data;
    }
};

const GetHistoryCommissionTopup = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetHistoryCommissionTopup
        });
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetHistoryCommissionTopup);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};


const RegisterUser = async (loginPayload) => {
    try {
        var common_axios = axios.create({
            baseURL: urlRegister
        });
        const response = await common_axios.post(urlRegister, loginPayload);
        return response;
    } catch (error) {
        console.log('Error:', error);
        return error.response;
    }
}

const GetInfo = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetInfo
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetInfo);
        return response.data.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const GetTONPrice = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetTONPrice
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetTONPrice);
        return response.data.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const GetHistoryConvertTONtoTFARM = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetHistoryConvertTONtoTFARM
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetHistoryConvertTONtoTFARM);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const ConvertTONtoTFARM = async (data, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlConvertTONtoTFARM
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlConvertTONtoTFARM, data);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

const TransferTFARM = async (data, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlTransferTFARM
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlTransferTFARM, data);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

const GetHistoryTransferTFARM = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetHistoryTransferTFARM
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetHistoryTransferTFARM);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const ConvertTPLANTtoTON = async (data, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlConvertTPLANTtoTON
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlConvertTPLANTtoTON, data);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

const GetHistoryConvertTPLANTtoTON = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetHistoryConvertTPLANTtoTON
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetHistoryConvertTPLANTtoTON);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const CheckInDaily = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlCheckInDaily
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlCheckInDaily);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const IsCheckInDaily = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlIsCheckInDaily
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlIsCheckInDaily);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const GetQuestInfo = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetQuestInfo
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetQuestInfo);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const ClaimQuest = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlClaimQuest
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlClaimQuest);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const CheckJoinTelegramChannel = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlCheckJoinTelegramChannel
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlCheckJoinTelegramChannel);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const CheckJoinTelegramGroup = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlCheckJoinTelegramGroup
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlCheckJoinTelegramGroup);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const CreatePincode = async (data, token, validationWebApp) => {
    try {
        var common_axios = axios.create({
            baseURL: urlCreatePincode
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        if(validationWebApp) {
            common_axios.defaults.headers.common['validationwebapp'] = validationWebApp;
        }
        // Check your Header
        const response = await common_axios.post(urlCreatePincode, data);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

const ShowSeedPharse = async (data, token, validationWebApp) => {
    try {
        var common_axios = axios.create({
            baseURL: urlShowSeedPharse
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        if(validationWebApp) {
            common_axios.defaults.headers.common['validationwebapp'] = validationWebApp;
        }
        // Check your Header
        const response = await common_axios.post(urlShowSeedPharse, data);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const GetHistoryWithdrawTPLANT = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetHistoryWithdrawTPLANT
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetHistoryWithdrawTPLANT);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const WithdrawTPLANT = async (data, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlWithdrawTPLANT
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlWithdrawTPLANT, data);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

const GetHistoryConvertTPLANT = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetHistoryConvertTPLANT
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetHistoryConvertTPLANT);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const ConvertTPLANT = async (data, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlConvertTPLANT
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlConvertTPLANT, data);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

const UpdateUsernameX = async (data, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlUpdateUsernameX
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlUpdateUsernameX, data);
        return response;
    } catch (error) {
        return error.response.data;
    }
};

const CheckConvertTfarm = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlCheckConvertTfarm
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlCheckConvertTfarm);
        return response.data;
    } catch (error) {
        return error.response.data.data;
    }
};

const GetAccumulatedTPLANT = async (token, validationWebApp) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetAccumulatedTPLANT
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        if(validationWebApp) {
            common_axios.defaults.headers.common['validationwebapp'] = validationWebApp;
        }
        // Check your Header
        const response = await common_axios.post(urlGetAccumulatedTPLANT);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const UpdateToAccumulatedTPLANT = async (token, validationWebApp) => {
    try {
        var common_axios = axios.create({
            baseURL: urlUpdateToAccumulatedTPLANT
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        if(validationWebApp) {
            common_axios.defaults.headers.common['validationwebapp'] = validationWebApp;
        }
        // Check your Header
        const response = await common_axios.post(urlUpdateToAccumulatedTPLANT);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};








export {
    LoginWebApp,
    RegisterUser,
    GetInfo,
    ConvertTONtoTFARM,
    GetTONPrice,
    GetHistoryConvertTONtoTFARM,
    TransferTFARM,
    GetHistoryTransferTFARM,
    ConvertTPLANTtoTON,
    GetHistoryConvertTPLANTtoTON,
    TopUpPackage,
    GetHistoryTopUp,
    GetListReferral,
    TopUpMembership,
    GetIsMembership,
    GetHistoryCommissionTopup,
    CheckInDaily,
    IsCheckInDaily,
    GetQuestInfo,
    ClaimQuest,
    CheckJoinTelegramGroup,
    CheckJoinTelegramChannel,
    CreatePincode,
    ShowSeedPharse,
    GetHistoryWithdrawTPLANT,
    WithdrawTPLANT,
    ConvertTPLANT,
    GetHistoryConvertTPLANT,
    UpdateUsernameX,
    CheckConvertTfarm,
    GetAccumulatedTPLANT,
    UpdateToAccumulatedTPLANT
};

export default host;