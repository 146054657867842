import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Link, useSearchParams } from 'react-router-dom';

import Banner from '../assets/images/banner.webp';
import Banlance from '../assets/images/Balance.png';
import Chrome_Icon from '../assets/images/icons/google-chrome-icon.svg';
import Edge_Icon from '../assets/images/icons/edge-browser-icon.svg';
import Opera_Icon from '../assets/images/icons/opera-icon.svg';
import { FaGlobe, FaTelegramPlane } from "react-icons/fa";

const HomePage = () => {

    const [ref, setRef] = useState(null);

    const [searchParams] = useSearchParams();
    // const refUrl = searchParams.get('ref')
    //setRef(searchParams.get('ref')); 
    useEffect(() => {
        const loadRef = () => {
            const user = localStorage.getItem('user');
            if (user) {
                const getId = (JSON.parse(user)).id;
                setRef(getId);
            } else {
                setRef(searchParams.get('ref'));
            }
        }
        loadRef();
    }, [ref]);

    const fullRefUrl = `https://t.me/tfarmpixelBot?start=${ref}`;

    return (
        <React.Fragment>
            <section className="video_section section-maxWidth">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 col-md-12">
                            <div className="section_title">
                                <h2>Raise your farm <br /> Take profit on Telegram</h2>
                            </div>
                            <div className="section_desc">
                                Log in to your Telegram account and immediately receive a plant right on the Telegram WebApp interface. Take good care of your plant to earn profits.
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-12">
                                    <h5 className="mt-5">Play with Telegram</h5>
                                    <div className="list-browser d-flex align-items-center justify-content-start">
                                        <a href={fullRefUrl} className="btn pixel-btn" target="_blank">
                                            <FaTelegramPlane />
                                            <span>Telegram</span>

                                        </a>
                                    </div>
                                </div>                               
                                {/* <div className="col-lg-4 col-12">
                                    <h5 className="mt-5">Play with Web</h5>
                                    <div className="list-browser d-flex align-items-center justify-content-start">
                                        <a href="#" className="btn pixel-btn">
                                            <FaGlobe />
                                            <span>Website</span>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                            {/* <h5 className="mt-5">Add to browser</h5>
                            <div className="list-browser d-flex align-items-center justify-content-start">
                                <a href="#" className="btn pixel-btn">
                                    <img src={Chrome_Icon} alt="" />
                                    <span>Chrome</span>
                                </a>
                                <a href="#" className="btn pixel-btn">
                                    <img src={Edge_Icon} alt="" />
                                    <span>Edge</span>
                                </a>
                                <a href="#" className="btn pixel-btn">
                                    <img src={Opera_Icon} alt="" />
                                    <span>Opera</span>
                                </a>
                            </div> */}
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="section_img">
                                <img alt="banner" src={Banlance} />
                                {/* <img alt="banner" src={Banner} />
                                <video muted="" loop={true} autoPlay={true} playsInline={true} ><source src="https://cdn.xpet.tech/video1.mp4" /></video>
                                <div className="section_img_back"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default HomePage;
