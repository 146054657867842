import React, { useState, useEffect } from "react";

import HeaderPage from "./Header";
import FooterPage from "./Footer";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}

const LayoutDashboard = ({children}) => {
 
    const [collapsed, setCollapsed] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const openMenu = () => {
        setCollapsed(!collapsed);
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
      if( windowDimensions.width >= 767){
        setCollapsed(true)
        return () => {
            window.removeEventListener('resize', handleResize);
            setCollapsed(false)
        }
      } else{
        return () => {
            window.removeEventListener('resize', handleResize);
            setCollapsed(false)
        }
      }
       
    },[windowDimensions]);

    return (
        <React.Fragment>
            <div className="page_wrapper">
                <HeaderPage 
                    collapsed={collapsed}
                    openLeftMenuCallBack={openMenu}
                />
                <>{children}</>
                <FooterPage />
            </div>
        </React.Fragment>
    )
}

export default LayoutDashboard
