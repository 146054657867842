import React, { useEffect, useState } from 'react';
import ReferralList from './referralList';
import { TelegramProvider, useTelegram } from "./lib/TelegramProvider"

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { QRCode } from 'react-qrcode-logo';
import Lottie from 'react-lottie';
import loadingAnimation from '../../../assets/images/json/loadingAnimation.json'


const WebApp = ({ onScriptLoad }) => {

    const user_webapp = localStorage.getItem("user_webapp");

    if (isMobile) {
        return (
            <div>
                {
                    user_webapp != null ?
                        <ReferralList jsonString={user_webapp} />
                        :
                        <div className='qr-wrap text-center d-flex align-items-center justify-content-center flex-column'>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: loadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                height={200}
                                width={200}
                            />
                            <h3 className='mb-3'>Connecting WebApp.....</h3>
                        </div>
                }
                
            </div>
        )
    } else {
        return (

            <ReferralList jsonString={user_webapp} />
            
            // <div className='qr-wrap text-center d-flex align-items-center justify-content-center flex-column'>
            //     <h3 className='mb-3'>Please, play game on your <br /> Telegram WebApp mobile</h3>
            //     {
            //         user_webapp != null ?
            //             <QRCode
            //                 value={`https://t.me/tfarmpixelBot?start=${user_webapp.id}`}
            //                 size={200}
            //                 logoImage='https://tfarm.io/icontFarm.png'
            //                 qrStyle='dots'
            //                 logoWidth={50}
            //                 bgColor='#FFFFFF'
            //                 logoOpacity={1}
            //                 fgColor='#000000'
            //                 quietZone={10}
            //                 ecLevel='L'
            //                 eyeRadius={100}
            //             />
            //             :
            //             <>
            //                 <Lottie
            //                     options={{
            //                         loop: true,
            //                         autoplay: true,
            //                         animationData: loadingAnimation,
            //                         rendererSettings: {
            //                             preserveAspectRatio: 'xMidYMid slice'
            //                         }
            //                     }}
            //                     height={200}
            //                     width={200}                       
            //                 />
            //             </>
            //     }
            // </div>
        )
    }
}

const WithTelegramProviderReferralList = () => {
    return (
        <TelegramProvider>
            <WebApp />
        </TelegramProvider>
    )
}
export default WithTelegramProviderReferralList;