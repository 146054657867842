import React, { useState, useEffect, useRef } from "react";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import QuestAction from "./questAction";
import QuestDaily from "./questDaily";
import Carot_Icon from '../../../assets/images/icons/Carrot.png';
import Logo from '../../../assets/images/Logo.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';

import {
    ClaimQuest,
    GetQuestInfo,
    GetInfo
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';


const QuestAppWrap = () => {
    const user_webapp = localStorage.getItem("user_webapp");

    const token = localStorage.getItem("token");
    const { toast } = useToast();
    const { user, setUser } = useUser();

    const [questInfo, setQuestInfo] = useState(null);
    const [loadingSend, setLoadingSend] = useState(false);
    const [messageErr, setMessageErr] = useState('');

    const handleClaimQuest = async () => {
        setLoadingSend(true);
        const responseClaimQuest = await ClaimQuest(token);
        if (responseClaimQuest) {
            if (responseClaimQuest.status.code === 400) {
                setMessageErr(responseClaimQuest.status.message);
                setTimeout(() => {
                    setMessageErr('');
                }, 3000)
            }
            if (responseClaimQuest.status.code === 200) {
                await loadQuestInfo();
                setMessageErr('Claim Success');
                setTimeout(() => {
                    setMessageErr('');
                }, 3000);

            }
            setLoadingSend(false);
        }
    }

    const loadQuestInfo = async () => {
        if (token) {
            const responseQuestInfo = await GetQuestInfo(token);
            if (responseQuestInfo.status.code === 200) {
                setQuestInfo(responseQuestInfo.data);
            } else {
                setQuestInfo(null);
            }
        }
    }

    useEffect(() => {

        loadQuestInfo();

    }, []);

    useEffect(() => {
        const fetchTonPrice = async () => {
            try {

                const responseInfo = await GetInfo(token);

                const userData = {
                    id: responseInfo.id,
                    username: responseInfo.lastname + " " + responseInfo.firstname,
                    email: responseInfo.email,
                    photo: responseInfo.photo,
                    hexAddress: responseInfo.hexAddress,
                    bounceableAddress: responseInfo.bounceableAddress,
                    createdAtTimestamp: responseInfo.createdAtTimestamp,
                    TFARM_Balance: responseInfo.TFARM_Balance,
                    TON_Balance: responseInfo.TON_Balance,
                    TPLANT_Balance: responseInfo.TPLANT_Balance,
                    isActived: responseInfo.isActived,
                    // questPoint: responseQuestInfo.data.questPoint,
                    // isCheckDaily: responseQuestInfo.data.isCheckDaily,
                    // isJoinTelegramChannel: responseQuestInfo.data.isJoinTelegramChannel,
                    // isJoinTelegramGroup: responseQuestInfo.data.isJoinTelegramGroup
                };

                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));

            } catch (error) {
                // console.error(error.message);
            }
        };
        fetchTonPrice();
    }, []);

    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {
                            loadingSend &&
                            <div className="loading-wrap">
                                <div className="loading-send">
                                    <img src={Loss_Icon} alt="" />
                                    <h3>Claim TPLANT</h3>
                                    <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                                </div>
                            </div>
                        }
                        <div className="quest-wrap">
                            <Link className="navbar-brand" to="/">
                                <img src={Logo} alt="Logo" />
                            </Link>
                            <div className="quest-container gap-2">
                                <div className="text-center">
                                    <h3>Enter the Telegram group and unite with other players to receive bonus points!</h3>
                                    <div className="reward-points-top">
                                        <img src={Carot_Icon} alt="" />
                                        <h3>{questInfo ? questInfo.questPoint : <Skeleton />}</h3>
                                        {
                                            questInfo ?
                                                <>
                                                    {
                                                        questInfo.questPoint > 0 ? <button className="btn pixel-btn mb-2" onClick={handleClaimQuest}>Claim</button> : ""
                                                    }
                                                </>
                                                :
                                                ""
                                        }
                                        {
                                            messageErr && <h3 className="mb-2 text-danger text-message">{messageErr}</h3>
                                        }
                                    </div>
                                    <Tabs
                                        defaultActiveKey="DailyQuest"
                                        className="mb-3"
                                        variant="pills"
                                    >
                                        <Tab eventKey="DailyQuest" title={<>Daily Quest</>}>
                                            <QuestDaily questInfo={questInfo} loadQuestInfo={loadQuestInfo} />
                                        </Tab>
                                        <Tab eventKey="ActionQuest" title={<>Action Quest</>}>
                                            <QuestAction questInfo={questInfo} loadQuestInfo={loadQuestInfo} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default QuestAppWrap;
