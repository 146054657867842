import React, {
    useState,
    useEffect,
    useCallback
} from "react";

import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import LayoutDashboard from "./layout/Layout";
import HomePage from "./pages/HomePage";
import PlayGame from "./pages/playGame/play";
import WithDraw from "./pages/dashBoard/withDraw";
import DashBoardMain from "./pages/dashBoard";
import RouteGuard from "./components/RouteGuard";

import { jwtDecode } from "jwt-decode";
import { useUser } from './context/UserContext';

import { setAuthToken } from "./helpers/setAuthToken";

import WithTelegramProvider from "./pages/playWebApp";

import WithTelegramProviderCommission from "./pages/dashBoard/packageList";
import MemberShip from "./pages/dashBoard/memberShip/memberShip";
import WithTelegramProviderPackage from "./pages/dashBoard/packageList";
import WithTelegramProviderReferralInfo from "./pages/dashBoard/referralInfo";
import WithTelegramProviderHistoryList from "./pages/dashBoard/historyList";
import WithTelegramProviderMainWebAppWrap from "./pages/dashBoard/mainWebApp";
import WithTelegramProviderReferralList from "./pages/dashBoard/referralList";
import WithTelegramProviderCommissionTopup from "./pages/dashBoard/commissionTopup";
import WithTelegramProviderConvertWebAppWrap from "./pages/dashBoard/convertWebApp";
import Deposit from "./pages/dashBoard/convertWebApp/deposit";
import TransferTfarmWebApp from "./pages/dashBoard/convertWebApp/transferTfarm";
import WithDrawWebApp from "./pages/dashBoard/convertWebApp/withDraw";
import ConvertTplantToTonWebApp from "./pages/dashBoard/convertWebApp/convertTplantToTon";
import WithTelegramProviderQuestWebAppWrap from "./pages/dashBoard/quest";

import WithTelegramProviderMainApp from "./pages/MainApp";
import WithTelegramProviderMarketApp from "./pages/MarketApp";
import WalletAppItemWrap from "./pages/MainApp/wallet/walletItem";

function App() {
    const { setUser } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    //check jwt token
    const token = localStorage.getItem("token");
    if (token) {
        setAuthToken(token);
    }

    const logOut = useCallback(() => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('token_webapp');
        localStorage.removeItem('userPlay');
        if(location.pathname === '/main-app'){
            navigate("/main-app");
        } else{
            navigate("/main-app");
        }
       
    }, []);

    useEffect(() => {
        let timerRef = null;
        const token = localStorage.getItem("token");
        if (token) {
            const decoded = jwtDecode(token);

            const expiryTime = (new Date(decoded.exp * 1000)).getTime();

            const currentTime = (new Date()).getTime();

            const timeout = expiryTime - currentTime;

            const onExpire = () => {
                logOut();
            };

            if (timeout > 0) {
                // token not expired, set future timeout to log out and redirect
                timerRef = setTimeout(onExpire, timeout);
            } else {
                // token expired, log out and redirect
                onExpire();
            }

            // Clear any running timers on component unmount or token state change
            return () => {
                clearTimeout(timerRef);
            };
        }
    }, [logOut, navigate]);

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<LayoutDashboard><HomePage /></LayoutDashboard>} />
                <Route path="/play-webapp" element={<WithTelegramProvider />} />
                <Route path="/commission-quest" element={<WithTelegramProviderCommission />} />
                {/* <Route path="/membership" element={<MemberShip />} /> */}
                <Route path="/package" element={<WithTelegramProviderPackage />} />
                {/* <Route path="/referral" element={<WithTelegramProviderReferralInfo />} /> */}
                <Route path="/history" element={<WithTelegramProviderHistoryList />} />
                <Route path="/main" element={<WithTelegramProviderMainWebAppWrap />} />
                <Route path="/referral-list" element={<WithTelegramProviderReferralList />} />
                <Route path="/commission-topup" element={<WithTelegramProviderCommissionTopup />} />
                <Route path="/convert" element={<WithTelegramProviderConvertWebAppWrap />} />
                <Route path="/deposit" element={<Deposit />} />
                <Route path="/transfer-tfarm" element={<TransferTfarmWebApp />} />
                <Route path="/withdraw" element={<WithDrawWebApp />} />
                <Route path="/tplant-to-ton" element={<ConvertTplantToTonWebApp />} />

                <Route path="/quest" element={<WithTelegramProviderQuestWebAppWrap />} />

                <Route path="/main-app" element={<WithTelegramProviderMainApp />} />
                <Route path="/market" element={<WithTelegramProviderMarketApp />} />
                <Route path="/wallet-app" element={<WalletAppItemWrap />} />
                
                <Route element={<RouteGuard />}>
                    <Route path="/play" element={<LayoutDashboard><PlayGame /></LayoutDashboard>} />
                    <Route path="/dashboard" element={<LayoutDashboard><DashBoardMain /></LayoutDashboard>} />
                </Route>
            </Routes>
        </div>

    );
}

export default App;
