import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

import { FiArrowDown } from "react-icons/fi";

import moment from 'moment';
import 'moment-timezone';
import {
    GetAccumulatedTPLANT,
    UpdateToAccumulatedTPLANT,
    GetInfo
} from '../../../api/index';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import { useUser } from '../../../context/UserContext';

import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';
import TCoin_Icon from '../../../assets/images/icons/FCoin.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';


const AccumulativeTplantWebApp = ({exchangeTplantTon}) => {

    const token = localStorage.getItem("token");
    const tonPrice = localStorage.getItem("tonPrice");
    const telegramWebApp = localStorage.getItem("telegramWebApp");
    const result_telegramWebApp = telegramWebApp.replace(/"/g, '');

    const { user, setUser } = useUser();

    const [amountTPLANT, setAmountTPLANT] = useState(0);

    const [estimatedTime, setEstimatedTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [accumulatedMessage, setAccumulatedMessage] = useState('');
    const [accumulatedMessageErr, setAccumulatedMessageErr] = useState('');

    const [accumulativeData, setAccumulativeData] = useState([]);
    
    const handleAccumulative = async () => {
        setLoadingSend(true);
        const responseAccumulated = await UpdateToAccumulatedTPLANT(token, result_telegramWebApp);
        console.log(responseAccumulated);
        if (responseAccumulated) {
            if (responseAccumulated.status.code === 400 | responseAccumulated.status.code === 440) {
                setAccumulatedMessageErr(responseAccumulated.status.message);
                setAccumulatedMessage('');
            }
            if (responseAccumulated.status.code === 200) {
                setAccumulatedMessage(responseAccumulated.status.message);
                setAccumulatedMessageErr("");
                setTimeout(() => {
                    GetAccumulated();
                    GetInfoData();
                }, 2000)
            }
            setLoadingSend(false);
        }
    }

    const GetAccumulated = async () => {
        if (token) {
            try {
                setLoading(true);
                const responseList = await GetAccumulatedTPLANT(token, result_telegramWebApp);
                if (responseList.status.code !== 440) {
                    setAccumulativeData(responseList.data);
                } else {
                    setAccumulativeData([]);
                }
            } catch (error) {
                // console.error(error.message);
            }
        }
    };

    const GetInfoData = async () => {
        const responseInfo = await GetInfo(token);
        if(responseInfo){
            const userData = {
                id: responseInfo.id,
                username: responseInfo.lastname + " " + responseInfo.firstname,
                email: responseInfo.email,
                photo: responseInfo.photo,
                hexAddress: responseInfo.hexAddress,
                bounceableAddress: responseInfo.bounceableAddress,
                nonBounceableAddress : responseInfo.nonBounceableAddress,
                createdAtTimestamp: responseInfo.createdAtTimestamp,
                TFARM_Balance: responseInfo.TFARM_Balance,
                TPLANT_Balance_Accumulate: responseInfo.TPLANT_Balance_Accumulate,
                TPLANT_Balance: responseInfo.TPLANT_Balance,
                isActived: responseInfo.isActived,    
                pincode: responseInfo.pincode               
            };
    
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
        }
    }

    useEffect(() => {
        GetAccumulated();
    }, []);

    return (
        <React.Fragment>
            <div className="position-relative">
                {
                    loadingSend &&
                    <div className="loading-wrap">
                        <div className="loading-send">
                            <img src={Loss_Icon} alt="" />
                            <h3>Accumulative TPLANT</h3>
                            <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                        </div>
                    </div>
                }
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="">
                            <div className="convertBoxTop">
                                <h2 className="convertBoxTitle text-start">Accumulative TPLANT</h2>
                                <span className="text-start d-block mb-3 font-sm text-warning">Every 20 days, the accumulation will be calculated again from the beginning.</span>
                                <div className="convertBoxIn d-flex">
                                    <div className="convertBoxIn_Left">
                                        {
                                            accumulativeData && <span className="text-warning d-block text-start">Accumulative TON : {accumulativeData.accumulatedTON} <img src={Ton_Icon} alt="" width={16} /></span>
                                        }
                                        {
                                            accumulativeData && <span className="text-warning d-block text-start">Accumulative TPLANT : {accumulativeData.accumulatedTPLANT} <img src={TCoin_Icon} alt="" width={16} /></span>
                                        }
                                        {
                                            accumulativeData && <span className="text-warning d-block text-start">Days In Current Cycle : {accumulativeData.daysInCurrentCycle}</span>
                                        }
                                        {
                                            accumulativeData && <span className="text-warning d-block text-start">Total Convert : {accumulativeData.totalConvert} <img src={Ton_Icon} alt="" width={16} /></span>
                                        }
                                        
                                        <Table responsive className="table-hover table-bordered">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>Current Total Convert</th>
                                                    <th>Daily Accumulate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="text-center">
                                                    <td>
                                                        <span className="fw-bolder"> 0 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-bolder"> 0.01 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>
                                                        <span className="fw-bolder"> 5 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-bolder"> 0.35 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>
                                                        <span className="fw-bolder"> 10 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-bolder"> 0.7 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>
                                                        <span className="fw-bolder"> 20 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-bolder"> 1.4 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>
                                                        <span className="fw-bolder"> 200 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-bolder"> 14 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>
                                                        <span className="fw-bolder"> 1000 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-bolder"> 70 <img src={Ton_Icon} alt="" width={16} /></span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <h3 className="mt-3">You will accumulate from off-chain.</h3>
                                {
                                    estimatedTime && <h3 className="mb-0">Estimate wait time: {estimatedTime}</h3>
                                }
                                {
                                    accumulatedMessage && <h3 className="mb-0 text-success text-message">{accumulatedMessage}</h3>
                                }
                                {
                                    accumulatedMessageErr && <h3 className="mb-0 text-danger text-message">{accumulatedMessageErr}</h3>
                                }
                                <button className="btn pixel-btn" onClick={handleAccumulative}>Request Accumulative</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AccumulativeTplantWebApp;
