import React, { useEffect, useState } from 'react';
import { TelegramProvider, useTelegram } from "./lib/TelegramProvider"
import MainWebApp from './mainApp';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { QRCode } from 'react-qrcode-logo';
import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/images/json/loadingAnimation.json'

import {
    LoginWebApp,
    GetInfo
} from '../../api/index';

import { useUser } from '../../context/UserContext';

const WebApp = ({ onScriptLoad }) => {

    const { user, webApp } = useTelegram();
    const { setUser } = useUser();

    const telegramWebApp = localStorage.getItem("telegramWebApp") && localStorage.getItem("telegramWebApp").replace(/"/g, '');
    
    const [jsonString, setJsonString] = useState(null);
    const [dataUserJson, setDataUserJson] = useState(null);

    useEffect(() => {
        // Execute actions when the user state is updated
        //console.log('User data in WebApp:', user);
        if (user) {
            getDataUser(user);
        }
        // getDataUser(user);
    }, [user]);

    const getDataUser = async (user) => {

        // const loginPayload = {
        //     telegramId: 860748460,
        //     first_name: 'Harry Nguyen',
        //     last_name: '|',
        //     platform: 'WEBAPP_TELEGRAM'
        // };

        const loginPayload = {
            telegramId: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            platform: 'WEBAPP_TELEGRAM'
        };

        const loginWebApp = await LoginWebApp(loginPayload);
        if (loginWebApp.data.data) {
            let dataJson = {
                id: loginWebApp.data.data.id,
                token: loginWebApp.data.data.token,
                first_name: loginWebApp.data.data.first_name,
                last_name: loginWebApp.data.data.last_name,
                hash_infor: telegramWebApp
            }
            console.log(dataJson);
            setJsonString(JSON.stringify(dataJson));
            const responseInfo = await GetInfo(loginWebApp.data.data.token);

                const userData = {
                    id: responseInfo.id,
                    username: responseInfo.lastname + " " + responseInfo.firstname,
                    email: responseInfo.email,
                    photo: responseInfo.photo,
                    hexAddress: responseInfo.hexAddress,
                    bounceableAddress: responseInfo.bounceableAddress,
                    nonBounceableAddress : responseInfo.nonBounceableAddress,
                    createdAtTimestamp: responseInfo.createdAtTimestamp,
                    TFARM_Balance: responseInfo.TFARM_Balance,
                    TON_Balance: responseInfo.TON_Balance,
                    TPLANT_Balance: responseInfo.TPLANT_Balance,
                    isActived: responseInfo.isActived,    
                    pincode: responseInfo.pincode               
                    // questPoint: responseQuestInfo.data.questPoint,
                    // isCheckDaily: responseQuestInfo.data.isCheckDaily,
                    // isJoinTelegramChannel: responseQuestInfo.data.isJoinTelegramChannel,
                    // isJoinTelegramGroup: responseQuestInfo.data.isJoinTelegramGroup
                };
                setDataUserJson(userData);

                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));
                localStorage.setItem("user_webapp", JSON.stringify(dataJson));
                localStorage.setItem("token", loginWebApp.data.data.token);
        }

    }

    if (isMobile) {
        return (
            <div>
                {
                    dataUserJson != null ?
                        <MainWebApp jsonString={jsonString} />
                        :
                        <div className='qr-wrap text-center d-flex align-items-center justify-content-center flex-column'>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: loadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                height={200}
                                width={200}
                            />
                            <h3 className='mb-3'>Connecting WebApp.....</h3>
                        </div>
                }
            </div>
        )
    } else {
        return (
            <div>
                {
                    dataUserJson != null ?
                        <MainWebApp jsonString={jsonString} />
                        :
                        <div className='qr-wrap text-center d-flex align-items-center justify-content-center flex-column'>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: loadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                height={200}
                                width={200}
                            />
                            <h3 className='mb-3'>Connecting WebApp.....</h3>
                        </div>
                }
            </div>
        )
    }
}

const WithTelegramProviderMarketApp = () => {
    return (
        // <WebApp />
        <TelegramProvider>
            <WebApp />
        </TelegramProvider>
    )
}
export default WithTelegramProviderMarketApp;