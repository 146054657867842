import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
//import { useMediaQuery } from 'react-responsive';

const PlayGameDesktop = () => {

    const unityRef = useRef();

    const { unityProvider, requestFullscreen, isLoaded, loadingProgression,
        addEventListener, removeEventListener, sendMessage,
    } = useUnityContext({
        loaderUrl: `${process.env.PUBLIC_URL}/playGame/build/Build.loader.js`,
        dataUrl: `${process.env.PUBLIC_URL}/playGame/build/webgl.data`,
        frameworkUrl: `${process.env.PUBLIC_URL}/playGame/build/build.framework.js`,
        codeUrl: `${process.env.PUBLIC_URL}/playGame/build/build.wasm`,
        streamingAssetsUrl: "streamingassets",
        webGLContextAttributes: {
            alpha: true,
            antialias: true,
            depth: true,
            failIfMajorPerformanceCaveat: true,
            powerPreference: "high-performance",
            premultipliedAlpha: true,
            preserveDrawingBuffer: true,
            stencil: true,
            desynchronized: true,
            xrCompatible: true,
        },
    });
    const loadingPercentage = Math.round(loadingProgression * 100);

    const refreshPage = () => {
        window.location.reload(false);
    }


    /// From React to Unity
    //  gameObjectName: GameManager,
    //  methodName: MessageFromjavaScript,
    //  parameter: message   
    function handleLoadFirstData(isLoaded) {
        const userPlay = localStorage.getItem("userPlay");
        //console.log(userPlay)
        if (isLoaded) {
            sendMessage("GameManager", "MessageFromjavaScript", userPlay);
            //sendMessage("GameManager", "MessageFromjavaScript", 'message123456');
        }

    }

    // From Unity To React
    const returnUnityToReact = useCallback((message) => {
        //console.log(message)
        if (message === 'reloadPage') {
            refreshPage();
        }

    }, []);

    useEffect(() => {
        addEventListener("gamePlay", returnUnityToReact);
        return () => {
            removeEventListener("gamePlay", returnUnityToReact);
        };
    }, [addEventListener, removeEventListener, returnUnityToReact]);

    useEffect(() => {
        handleLoadFirstData(isLoaded);
    }, [isLoaded]);

    //Full Screen
    // function handleLoadFirstData() {
    //     requestFullscreen(true);
    // }

    return (
        <>
            {!isLoaded &&
                <div className='loading'>
                    <h3>Loading... ({loadingPercentage}%)</h3>
                </div>
            }
            <div ref={unityRef} className='text-center pb-5 game-wrap-play'>
                <Unity
                    unityProvider={unityProvider}
                    style={{
                        height: '100%',
                        width: '100%',
                        visibility: isLoaded ? "visible" : "hidden"
                    }}
                />
            </div>
            {/* <div className='text-center mb-5'>
                <button className='btn btn-primary' onClick={handleClickEnterFullscreen}>Enter Full Screen</button>
            </div>
            <div className='text-center mb-5'>
                <button className='btn btn-primary' onClick={handleClickSpawnEnemies}>Spawn Enemies</button>
            </div> */}

        </>
    );
};

// const PlayGameMobile = () => {
//     const { unityProvider, requestFullscreen, isLoaded, loadingProgression } = useUnityContext({
//         loaderUrl: `${process.env.PUBLIC_URL}/playGame/build/Build.loader.js`,
//         dataUrl: `${process.env.PUBLIC_URL}/playGame/build/webgl.data`,
//         frameworkUrl: `${process.env.PUBLIC_URL}/playGame/build/build.framework.js`,
//         codeUrl: `${process.env.PUBLIC_URL}/playGame/build/build.wasm`,
//         streamingAssetsUrl: "streamingassets",
//         webGLContextAttributes: {
//             alpha: true,
//             antialias: true,
//             depth: true,
//             failIfMajorPerformanceCaveat: true,
//             powerPreference: "high-performance",
//             premultipliedAlpha: true,
//             preserveDrawingBuffer: true,
//             stencil: true,
//             desynchronized: true,
//             xrCompatible: true,
//         },
//     });

//     const loadingPercentage = Math.round(loadingProgression * 100);
//     const unityRef = useRef();

//     return (
//         <>
//             {!isLoaded &&
//                 <div className='loading'>
//                     <h3>Loading... ({loadingPercentage}%)</h3>
//                 </div>
//             }
//             <div ref={unityRef} className='text-center game-wrap-play'>
//                 <Unity
//                     unityProvider={unityProvider}
//                     style={{
//                         height: '100%',
//                         width: '100%',
//                         visibility: isLoaded ? "visible" : "hidden"
//                     }}
//                 />
//             </div>
//         </>
//     );
// };                     

const PlayGame = () => {
    // const isDesktop = useMediaQuery({ minWidth: 768 });
    return (
        <>
            {/* {isDesktop ? <PlayGameDesktop /> : <PlayGameMobile />} */}
            <PlayGameDesktop />
        </>
    );
};

export default PlayGame;