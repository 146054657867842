import React, {useEffect} from 'react';
import { Outlet, useNavigate } from 'react-router-dom'
 
const RouteGuard = () => {
    const navigate = useNavigate();
    function hasJWT() {
        let flag = false;
        //check user has JWT token
        localStorage.getItem("token") !== null ? flag=true : flag=false
        return flag
    }

    useEffect(() => {
        hasJWT() ? <Outlet/> : navigate('/')
    }, []);
 
    return (
        hasJWT() ? <Outlet/> : navigate('/')
    );
};
 
export default RouteGuard;