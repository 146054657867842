import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';

import {
    GetHistoryTopUp,
} from '../../../api/index';

const HistoryListWrap = ({ jsonString }) => {
    
    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const dataJson = JSON.parse(jsonString);

    useEffect(() => {

        const historyTopUp = async () => {
            try {
                const responseList = await GetHistoryTopUp(dataJson.token);
                if (responseList.status.code !== 440) {
                    setSelectedList(responseList.data);
                } else {
                    setSelectedList([]);
                }
            } catch (error) {
                // console.error(error.message);
            }
        };
        historyTopUp();
    }, []);

    return (
        <>
            <div className='text-center commission-wrap'>
                <div className='commission-top mb-5'>
                    <h2>History Topup</h2>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-3'>
                        <Link to="/package" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Commission</Link>
                        <Link to="/main" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Home</Link>
                    </div>

                    <div className='list-history-info mb-3'>
                        {
                            currentItems.length ?
                                currentItems.map((item, index) => {
                                    return (
                                        <div className={(item.package === "BRONZE") ? 'commission-info-item copper-item' :
                                        (item.package === "SILVER") ? 'commission-info-item silver-item' :
                                        (item.package === "GOLD") ? 'commission-info-item gold-item' :
                                        (item.package === "DIAMOND") ? 'commission-info-item diamond-item' :
                                        'commission-info-item'} key={index}>
                                            <span className='title'>Package: {item.package}</span>
                                            <span className=''>Status: {item.status}</span>
                                            <span className='number d-flex gap-2 align-items-center justify-content-start'>Price: {item.amountConvert} <img src={Ton_Icon} alt="" className="img-fluid" width="16" height="16" /></span>
                                        </div>
                                    )
                                })
                                :
                                <div className="no-data text-center">
                                    <div className="text-center">
                                        <span className="d-block text-center text-white">No data.</span>
                                    </div>    
                                </div>
                        }
                    </div>
                    {
                        selectedList.length ?
                            <Pagination className="justify-content-end">
                                <Pagination.Prev
                                    onClick={() =>
                                        setCurrentPage((prevPage) =>
                                            prevPage > 1 ? prevPage - 1 : prevPage
                                        )
                                    }
                                    disabled={prevDisabled}
                                >
                                    <FiChevronsLeft />
                                </Pagination.Prev>
                                {pageNumbers.map((number) => (
                                    <Pagination.Item
                                        key={number}
                                        active={number === currentPage}
                                        onClick={() => handlePageChange(number)}
                                    >
                                        {number}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next
                                    onClick={() =>
                                        setCurrentPage((prevPage) =>
                                            prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                        )
                                    }
                                    disabled={nextDisabled}
                                >
                                    <FiChevronsRight />
                                </Pagination.Next>
                            </Pagination>
                            :
                            ""
                    }
                </div>
            </div>
        </>
    );
};
const HistoryList = ({ jsonString }) => {
    return (
        <HistoryListWrap jsonString={jsonString} />
    );
};

export default HistoryList;