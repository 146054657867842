import React, { useState, useEffect, useRef } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import moment from 'moment';
import 'moment-timezone';

import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';

import {
    FiCopy,
    FiCornerUpRight
} from "react-icons/fi";

import {
    ClaimQuest,
    GetQuestInfo,
    GetListReferral
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';

import Carot_Icon from '../../../assets/images/icons/Carrot.png';
import Chau_1 from '../../../assets/images/items/Chau cay 1.png';
import Chau_2 from '../../../assets/images/items/Chau cay 2.png';
import TCoin_Icon from '../../../assets/images/icons/FCoin.png';
import FCoin_Icon from '../../../assets/images/icons/FCoin_t.png';


const MyItemsAppWrap = () => {

    const token = localStorage.getItem("token");
    const { toast } = useToast();
    const { user, setUser } = useUser();

    const [show, setShow] = useState(false);

    const handleShowModalSell = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    };

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied successfully');
        } catch (err) {
            toast.warning('Copy failed');
        }
    };

    const shareButton = () => {
        let text = `TFarm offers you a golden opportunity for growth!\n🌟Let's harvest together and reap the rewards we deserve!🌾💰`
        window.location.href = `https://t.me/share/url?url=https://t.me/tfarmpixelBot?start=${user.id}&text=${text}`;
    }

    return (
        <React.Fragment>
            <div className="invite_code mt-3">
                <span className="d-block text-left invite_code_title text-dark">Invite Code</span>
                <div className="invite_code_container d-flex align-items-center justify-content-between gap-2">
                    <div className="left d-flex align-items-center">
                        <span className="text-white hexAddress d-flex align-items-center"><span className="invite_code_text">{user && user.id}</span>
                            <CopyToClipboard text={`https://t.me/tfarmpixelBot?start=${user ? user.id : ""}`}
                                onCopy={() => toast.success('Copied successfully')}>
                                <FiCopy size={20} title="Copy!" className="ms-2 copy text-warning" />
                            </CopyToClipboard>
                        </span>
                    </div>
                    <div className="right">
                        <button className="btn pixel-btn btn-checkin mt-0" onClick={() => shareButton()}><FiCornerUpRight /> Send</button>
                    </div>
                </div>
            </div>
            <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between gap-2">
                    <img src={FCoin_Icon} alt="" />
                    <span>TFARM</span>
                </div>
                <span>{user ? parseFloat(user.TFARM_Balance).toFixed(2) : 0}</span>
            </div>
            <div className="coin-item-wrap d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between gap-2">
                    <img src={TCoin_Icon} alt="" />
                    <span>TPLANT (In-Game)</span>
                </div>
                <span>{user ? parseFloat(user.TPLANT_Balance).toFixed(2) : 0}</span>
            </div>
            <hr/>
            <div className="market-body">
                <div className="prod-item">
                    <div className="prod-title">
                        Carrot
                    </div>
                    <div className="prod-img"><img src={Chau_1} alt="" /></div>
                    <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                    <button className="btn pixel-btn" onClick={handleShowModalSell}>Sell</button>
                </div>
                <div className="prod-item">
                    <div className="prod-title">
                        Carrot
                    </div>
                    <div className="prod-img"><img src={Chau_2} alt="" /></div>
                    <div className="prod-price">10 <img src={Carot_Icon} alt="" /></div>
                    <button className="btn pixel-btn">Sell</button>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sell Item</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="sell-wrap">
                        <label className="text-start d-block mb-2">Price item</label>
                        <input autoComplete="false" placeholder="0.000" className="input-group" />
                        <button className="btn pixel-btn btn-primary mt-2 w-100">Sell</button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default MyItemsAppWrap;
