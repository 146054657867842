import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';
import { QRCode } from 'react-qrcode-logo';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FcInfo } from "react-icons/fc";
import {
    FiCopy
} from "react-icons/fi";

import WithDraw from "./withDraw";
import ConvertTplantToTon from "./convertTplantToTon";
import TransferTfarm from "./transferTfarm";
import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';
import Logo from '../../../assets/images/Logo.png';

import {
    ConvertTONtoTFARM,
    GetTONPrice,
    GetHistoryConvert,
    GetInfo
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';


const ConvertWebAppWrap = () => {
    const user_webapp = localStorage.getItem("user_webapp");

    const token = localStorage.getItem("token");
    const { toast } = useToast();
    const { user, setUser } = useUser();

    useEffect(() => {
        const fetchTonPrice = async () => {
            try {
                const response = await GetTONPrice(token);
                const responseInfo = await GetInfo(token);

                const userData = {
                    id: responseInfo.id,
                    username: responseInfo.lastname + " " + responseInfo.firstname,
                    email: responseInfo.email,
                    photo: responseInfo.photo,
                    hexAddress: responseInfo.hexAddress,
                    bounceableAddress: responseInfo.bounceableAddress,
                    createdAtTimestamp: responseInfo.createdAtTimestamp,
                    TFARM_Balance: responseInfo.TFARM_Balance,
                    TON_Balance: responseInfo.TON_Balance,
                    TPLANT_Balance: responseInfo.TPLANT_Balance,
                    isActived: responseInfo.isActived,
                };
                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));
                localStorage.setItem('tonPrice', response.TON_prices_USDT ? response.TON_prices_USDT : 0);
            } catch (error) {
                // console.error(error.message);
            }
        };
        fetchTonPrice();
    }, []);

    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="membership-wrap convert-wrap">
                            <Link className="navbar-brand" to="/">
                                <img src={Logo} alt="Logo" />
                            </Link>
                            <div className="membership-item convert-wrap-item gap-2">
                                <Link to="/deposit" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >Deposit To Game</Link>
                                <Link to="/transfer-tfarm" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >Transfer TFARM</Link>
                                <Link to="/withdraw" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >Convert TON to TFARM</Link>
                                <Link to="/tplant-to-ton" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership w-100" >Convert TPLANT to TON</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ConvertWebAppWrap;
