import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import { FiTwitter } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";

import Logo from '../assets/images/Logo.png';
import FooterBg from '../assets/images/bg_footer.jpg';


const FooterPage = (props) => {

    const [collapsed, setCollapsed] = useState(true);

    return (
        <>
            <footer className="footer_wrap" style={{ backgroundImage: `url(${FooterBg})` }}>
                {/* container start */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <div className="footer-top">
                                <img src={Logo} alt="Logo" width={250} />
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://x.com/tfarm_community" target="_blank">
                                            <FiTwitter size={32} />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://t.me/tfarm_community" target="_blank">
                                            <FaTelegramPlane size={32} />
                                        </a>
                                    </li>
                                </ul>
                                <ul className="navbar-nav ml-auto">
                                    {/* <li className="nav-item">
                                        <a className="nav-link" href="#marketplace">
                                            Marketplace
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#news">
                                            News
                                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://docs.tfarm.io" target="_blank">
                                            Whitepaper
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://docs.tfarm.io/legal/privacy-policy" target="_blank">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://docs.tfarm.io/legal/term-of-use" target="_blank">
                                            Terms of use
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="line-center"></div>
                            {/* <div className="footer-bottom">
                                <span className="d-flex align-item-center justify-content-center">
                                    <span className="me-3 d-inline-block">Audited by:</span> 
                                    <svg
                                        width={120}
                                        height={28}
                                        viewBox="0 0 120 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g filter="url(#filter0_d_3559_45380)">
                                            <path
                                                d="M25.2296 20.3264C24.3668 20.3755 23.5018 20.3663 22.6403 20.2989C21.8996 20.1979 21.3028 19.7694 21.3916 18.8634C21.4834 17.884 22.2485 18.3737 22.6893 18.3493C23.9564 18.2789 25.2357 18.288 26.5058 18.3493C27.3506 18.386 27.7729 18.0616 27.7301 17.226C27.6933 16.4639 28.0116 15.5182 26.8119 15.2825C25.6856 15.0622 24.5685 14.7806 23.4452 14.5786C21.7895 14.2726 21.3977 13.1524 21.3885 11.6925C21.3702 9.12464 21.7405 8.35643 23.3228 7.90959C24.9051 7.46274 26.5548 7.73819 28.1739 7.6984C28.7462 7.6984 29.0216 8.20953 29.4287 8.48804C29.7776 8.72677 29.6735 9.13688 29.6307 9.47661C29.5634 10.003 29.1196 9.86836 28.7921 9.87142C27.4699 9.87142 26.1447 9.87142 24.8133 9.87142C24.2012 9.87142 23.5279 9.81326 23.5493 10.7467C23.5707 11.5272 23.2861 12.4423 24.4522 12.681C25.4897 12.8953 26.5303 13.1004 27.5679 13.3085C29.594 13.7155 30.2581 14.8388 30.0378 17.4617C29.8664 19.5368 29.0094 20.3295 26.9068 20.3478H25.2265L25.2296 20.3264Z"
                                                fill="#F0F0F0"
                                            />
                                        </g>
                                        <g filter="url(#filter1_d_3559_45380)">
                                            <path
                                                d="M32.2352 15.4815C32.2352 10.9457 32.3148 10.8661 36.8506 10.8661C39.85 10.8661 41.3589 13.1493 40.2999 16.0447C40.0918 16.617 39.6664 16.4609 39.2991 16.467C37.9739 16.4884 36.6456 16.4915 35.3203 16.467C34.7419 16.467 34.3103 16.5037 34.4021 17.2628C34.4664 17.8351 34.445 18.4044 35.2714 18.3982C36.5446 18.386 37.8188 18.3942 39.094 18.4227C39.4185 18.4227 39.8592 18.2452 40.0122 18.7288C40.1652 19.2124 39.9663 19.6072 39.5837 19.9347C39.2337 20.2354 38.7803 20.388 38.3197 20.3601C37.2516 20.3387 36.1773 20.3601 35.1091 20.3448C33.0739 20.3264 32.2567 19.5123 32.2322 17.4801C32.2291 16.8006 32.2352 16.1426 32.2352 15.4815ZM36.2507 14.7898C36.82 14.294 38.1422 15.4937 38.1269 13.7798C38.1269 12.1516 36.9027 12.6535 36.0457 12.5556C35.0449 12.4393 34.3287 12.681 34.4052 13.9053C34.494 15.3927 35.6662 14.3521 36.2507 14.7898Z"
                                                fill="#F0F0F0"
                                            />
                                        </g>
                                        <g filter="url(#filter2_d_3559_45380)">
                                            <path
                                                d="M83.338 18.3065C83.7954 18.2712 84.2548 18.2712 84.7122 18.3065C86.2425 18.5483 86.6037 17.8198 86.5731 16.4059C86.5486 15.1816 86.0956 14.7439 84.9479 14.8021C84.2868 14.8358 83.6196 14.7684 82.9585 14.8021C82.2087 14.8419 81.8659 14.6154 81.8598 13.7799C81.8536 12.9443 82.1995 12.7239 82.9555 12.776C83.6165 12.8219 84.2899 12.7117 84.9418 12.7943C86.2762 12.9627 86.5914 12.3414 86.5639 11.1263C86.5425 10.1745 86.166 9.847 85.2968 9.85924C84.2256 9.85924 83.1544 9.90821 82.0862 9.85924C81.6118 9.83169 80.8865 10.199 80.7488 9.30221C80.7157 9.12426 80.7195 8.94142 80.7599 8.765C80.8004 8.58858 80.8766 8.42234 80.9839 8.27658C81.0912 8.13083 81.2273 8.00864 81.3838 7.91762C81.5402 7.8266 81.7137 7.76867 81.8934 7.74742C83.7512 7.61888 85.6794 7.31588 87.4545 8.16672C88.8287 8.82169 89.346 11.6527 88.3329 13.0637C87.9381 13.6085 87.9289 13.8533 88.2748 14.4624C89.1195 15.9498 88.9695 17.6117 88.3421 19.1298C87.7728 20.5132 86.3986 20.3785 85.1713 20.3785C84.1521 20.3785 83.133 20.3479 82.1107 20.3785C81.8033 20.3946 81.4974 20.325 81.2272 20.1775C80.957 20.03 80.7331 19.8103 80.5804 19.543C80.2499 18.9706 80.1856 18.3187 81.1926 18.3401C81.9087 18.3401 82.6219 18.3401 83.335 18.3401L83.338 18.3065Z"
                                                fill="#6899E5"
                                            />
                                        </g>
                                        <g filter="url(#filter3_d_3559_45380)">
                                            <path
                                                d="M70.0213 15.607C70.006 10.9059 70.1162 10.7988 74.9182 10.8631C77.5626 10.8998 79.2275 13.7767 78.0064 16.1793C77.7829 16.62 77.3942 16.4578 77.0882 16.464C75.766 16.4823 74.4438 16.464 73.1094 16.464C72.5646 16.464 71.9525 16.3323 72.029 17.2566C72.0872 17.9667 72.2984 18.4135 73.0941 18.4044C74.2633 18.4044 75.4324 18.386 76.6016 18.4044C77.0056 18.4044 77.5809 18.1595 77.6972 18.8696C77.7921 19.4327 77.6054 19.9867 77.0484 20.0938C75.0498 20.4672 73.0115 20.6386 71.068 19.9285C70.3916 19.6806 70.0029 18.8788 70.0182 18.031C70.0335 17.1832 70.0213 16.4211 70.0213 15.607ZM74.046 14.8602C74.4591 14.1348 75.818 15.5825 75.864 13.8318C75.9007 12.326 74.8815 12.5862 74.0031 12.5617C73.0849 12.5342 72.0566 12.4423 72.0474 13.8135C72.0321 15.4631 73.3634 14.3185 74.046 14.8602Z"
                                                fill="#F0F0F0"
                                            />
                                        </g>
                                        <g filter="url(#filter4_d_3559_45380)">
                                            <path
                                                d="M60.2579 15.6039C60.2579 16.7761 60.1753 17.9544 60.2824 19.1144C60.3834 20.1948 59.9213 20.3998 58.9909 20.36C57.6166 20.3019 56.2363 20.36 54.8682 20.3355C53.191 20.3019 52.2147 19.5245 52.129 17.8871C52.0188 15.8059 52.129 13.7124 52.0831 11.6251C52.0647 10.5508 52.8238 10.9273 53.3563 10.8569C54.0327 10.7681 54.3816 10.9579 54.3571 11.7506C54.3081 13.4798 54.4122 15.2182 54.3204 16.9536C54.2592 18.1197 54.706 18.5237 55.8017 18.4196C56.2085 18.3735 56.6192 18.3735 57.026 18.4196C57.8768 18.5267 58.1767 18.2329 58.14 17.3423C58.0696 15.561 58.1584 13.7767 58.1064 11.9954C58.0819 11.1905 58.2379 10.7987 59.1622 10.8263C59.9733 10.8538 60.3467 11.016 60.2824 11.9342C60.2029 13.1554 60.2579 14.3827 60.2579 15.6039Z"
                                                fill="#F0F0F0"
                                            />
                                        </g>
                                        <g filter="url(#filter5_d_3559_45380)">
                                            <path
                                                d="M42.7361 15.5121C42.7085 11.0559 42.8952 10.8631 47.3269 10.8692C47.9834 10.8535 48.64 10.8965 49.2888 10.9977C49.7877 11.0896 49.9897 11.4966 49.9652 12.0384C49.9315 12.7056 49.4632 12.5525 49.0868 12.5587C48.1196 12.5587 47.1556 12.5587 46.1884 12.5587C45.3223 12.5587 44.8785 12.9351 44.906 13.8502C44.9366 14.9673 44.9672 16.0906 44.906 17.2169C44.8387 18.2116 45.3376 18.4411 46.1792 18.4411C47.0974 18.4227 48.0156 18.4197 48.9338 18.4411C49.3439 18.4411 49.8948 18.2299 49.9805 18.9645C50.057 19.6225 49.7693 20.1887 49.1908 20.2469C47.5381 20.4183 45.8456 20.6356 44.2266 20.1122C43.2778 19.8061 42.8248 18.9584 42.7667 17.9698C42.6963 17.1404 42.7361 16.3262 42.7361 15.5121Z"
                                                fill="#F0F0F0"
                                            />
                                        </g>
                                        <g filter="url(#filter6_d_3559_45380)">
                                            <path
                                                d="M62.8688 15.8824C62.8688 14.7623 62.8871 13.6452 62.8688 12.5158C62.8389 12.2081 62.9157 11.8994 63.0862 11.6415C63.2566 11.3835 63.5105 11.1919 63.8053 11.0987C65.0295 10.6733 66.3119 10.7529 67.5576 10.9916C67.6856 11.0121 67.8079 11.0588 67.9171 11.1287C68.0262 11.1987 68.1197 11.2904 68.1918 11.3981C68.2639 11.5058 68.313 11.6272 68.3361 11.7547C68.3591 11.8823 68.3556 12.0132 68.3258 12.1393C68.2064 12.7515 67.6953 12.5189 67.3372 12.5342C64.9622 12.6474 64.9591 12.6382 64.9591 15.0041C64.9591 16.4272 64.9255 17.8535 64.9775 19.2889C65.002 20.051 64.8061 20.3632 63.9736 20.3754C63.1411 20.3876 62.8106 20.0999 62.8596 19.2399C62.9147 18.1259 62.8688 17.0026 62.8688 15.8824Z"
                                                fill="#F0F0F0"
                                            />
                                        </g>
                                        <g filter="url(#filter7_d_3559_45380)">
                                            <path
                                                d="M3.95199 7.8548C5.53476 8.48636 6.92296 9.06079 8.32506 9.59507C9.64995 10.1 9.66076 10.0815 9.66076 11.4928C9.66076 15.2452 9.66076 18.998 9.66076 22.7513C9.66076 23.6068 9.61444 23.6485 8.87633 23.2794C5.32475 21.5114 2.75526 18.8523 1.47669 15.0475C1.17853 14.1196 1.01829 13.1529 1.00109 12.1785C0.988741 11.7615 1.07521 11.4527 1.55545 11.4419C2.03568 11.4311 2.23951 11.638 2.2704 12.1337C2.46342 15.1911 3.77132 17.7313 5.9208 19.8653C6.4856 20.425 7.09898 20.9335 7.75372 21.3848C8.09807 21.621 8.31734 21.6673 8.31734 21.1454C8.31734 17.8285 8.30962 14.5132 8.31734 11.1964C8.31734 10.8335 8.13204 10.7208 7.84483 10.6065C6.16323 9.93633 4.49245 9.23682 2.80622 8.57747C2.44026 8.4354 2.12216 8.26091 2.10363 7.87178C2.08355 7.41934 2.46805 7.24485 2.8371 7.0997C4.8754 6.29365 6.91061 5.47833 8.95354 4.68C9.15273 4.60125 9.44458 4.3094 9.59591 4.69544C9.72099 5.01662 9.82136 5.41657 9.40134 5.6451C9.12962 5.78551 8.84961 5.90927 8.56286 6.0157C7.09591 6.60248 5.62432 7.18772 3.95199 7.8548Z"
                                                fill="#F0F0F0"
                                            />
                                        </g>
                                        <g filter="url(#filter8_d_3559_45380)">
                                            <path
                                                d="M15.6151 7.81621C14.1466 7.22171 12.7321 6.64882 11.3177 6.0713C10.9822 5.94678 10.6543 5.80293 10.3356 5.64048C9.94798 5.42739 10.0731 5.05061 10.1657 4.75104C10.2908 4.34647 10.5873 4.59663 10.7926 4.67692C12.8927 5.50305 14.9881 6.34307 17.0851 7.17692C17.3939 7.29891 17.6209 7.48576 17.641 7.83782C17.6611 8.18989 17.4387 8.39681 17.1299 8.52189C15.7062 9.09426 14.283 9.66818 12.8603 10.2436C12.5391 10.3749 12.1793 10.4397 11.8643 10.7686L16.5277 12.4101C16.9868 12.5717 17.4449 12.7359 17.902 12.9027C18.5011 13.1189 18.6926 13.4493 18.5196 14.138C17.4279 18.4848 14.6978 21.4234 10.7556 23.3551C10.5193 23.4709 10.2568 23.6223 10.0777 23.3289C9.98658 23.2039 9.94251 23.0509 9.95324 22.8966C9.96398 22.7424 10.0288 22.5969 10.1364 22.4858C10.3989 22.2032 10.7417 22.041 11.0706 21.8681C13.8377 20.3733 15.7448 18.1266 16.8813 15.2112C17.2364 14.2971 17.0542 13.9141 16.1478 13.5451C14.758 12.9768 13.2988 12.5815 11.9399 11.9206C11.5277 11.7199 11.032 11.6921 10.6073 11.4573C10.3109 11.3029 10.0638 11.1856 10.0916 10.7532C10.1194 10.344 10.229 10.0846 10.6259 9.93017C12.0156 9.38971 13.3822 8.82763 14.7611 8.27637C15.0422 8.16982 15.3417 8.09879 15.6151 7.81621Z"
                                                fill="#6899E5"
                                            />
                                        </g>
                                        <path
                                            d="M108.614 18.5834L113.197 14.0001L108.614 9.41678"
                                            stroke="#566370"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M98.5302 19.5001V17.6668C98.5302 16.6943 98.9165 15.7617 99.6042 15.0741C100.292 14.3864 101.224 14.0001 102.197 14.0001H113.197"
                                            stroke="#566370"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <defs>
                                            <filter
                                                id="filter0_d_3559_45380"
                                                x="20.4563"
                                                y="7.65045"
                                                width="10.5482"
                                                height="14.5599"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter1_d_3559_45380"
                                                x="31.3048"
                                                y="10.8661"
                                                width="10.2592"
                                                height="11.3502"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter2_d_3559_45380"
                                                x="79.4364"
                                                y="7.58847"
                                                width="10.3535"
                                                height="14.6466"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter3_d_3559_45380"
                                                x="69.0913"
                                                y="10.8531"
                                                width="10.2494"
                                                height="11.4174"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter4_d_3559_45380"
                                                x="51.1516"
                                                y="10.8249"
                                                width="10.071"
                                                height="11.3927"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter5_d_3559_45380"
                                                x="41.7984"
                                                y="10.8661"
                                                width="9.12076"
                                                height="11.4279"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter6_d_3559_45380"
                                                x="61.9284"
                                                y="10.796"
                                                width="7.34921"
                                                height="11.4327"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter7_d_3559_45380"
                                                x="0.0735019"
                                                y="4.50012"
                                                width="10.5576"
                                                height="20.853"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                            <filter
                                                id="filter8_d_3559_45380"
                                                x="9.02516"
                                                y="4.52667"
                                                width="10.482"
                                                height="20.8252"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="0.926498" />
                                                <feGaussianBlur stdDeviation="0.463249" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_3559_45380"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_3559_45380"
                                                    result="shape"
                                                />
                                            </filter>
                                        </defs>
                                    </svg>
                                </span>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* container end */}
            </footer>
        </>
    );
}

export default FooterPage;
