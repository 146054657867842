import React, { useState, useEffect, useRef } from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import moment from 'moment';
import 'moment-timezone';
import {
    CheckInDaily,
} from '../../../api/index';

import { FiCheck } from "react-icons/fi";


import { useUser } from '../../../context/UserContext';

import Carot_Icon from '../../../assets/images/icons/Carrot.png';
import Loss_Icon from '../../../assets/images/icons/loss.png';

const QuestDaily = ({ questInfo, loadQuestInfo }) => {

    const token = localStorage.getItem("token");

    const [checkInDailyStatus, setCheckInDailyStatus] = useState(false);

    const [loadingSend, setLoadingSend] = useState(false);

    const handleCheckInDaily = async () => {
        setLoadingSend(true);
        const responseCheckInDaily = await CheckInDaily(token);
        if (responseCheckInDaily) {
            if (responseCheckInDaily.status.code === 400) {
                setCheckInDailyStatus(false)
            }
            if (responseCheckInDaily.status.code === 200) {
                if (responseCheckInDaily.isSuccess === false) {
                    setCheckInDailyStatus(false);
                } else {
                    setCheckInDailyStatus(true);
                    loadQuestInfo();
                }
            }
            setLoadingSend(false);
        }
    }

    const loadingQuestInfo = () => {
        if (questInfo != null) {
            setCheckInDailyStatus(questInfo.isCheckDaily)
        }
    }

    useEffect(() => {
        loadingQuestInfo();
    }, [questInfo]);

    return (
        <React.Fragment>
            <section className="daily_section">
                <div className="container position-relative">
                    {
                        loadingSend &&
                        <div className="loading-wrap">
                            <div className="loading-send">
                                <img src={Loss_Icon} alt="" />
                                <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                            </div>
                        </div>
                    }
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="convertBox pixel-box">
                                <div className="convertBoxTop">
                                    <div className="d-flex align-items-start gap-2">
                                        <div className="quest-box-left text-left">
                                            <span className="quest-title">Check-in</span>
                                            <span>Check in daily to accumulate points</span>
                                            {
                                                questInfo != null ?
                                                    <>
                                                        {
                                                            checkInDailyStatus ?
                                                                <span className="check-icon"><FiCheck /></span>
                                                                :
                                                                <button className="btn pixel-btn mt-0" onClick={handleCheckInDaily}>Check-In</button>
                                                        }
                                                    </>
                                                    :
                                                    <Skeleton />
                                            }
                                        </div>
                                        <div className="quest-box-right">
                                            <span>50</span>
                                            <img src={Carot_Icon} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default QuestDaily;
