import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import moment from 'moment';
import 'moment-timezone';

import Pagination from 'react-bootstrap/Pagination';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';

import {
    GetListReferral,
} from '../../../api/index';

const ReferralListWrap = ({ jsonString }) => {

    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const dataJson = JSON.parse(jsonString);

    useEffect(() => {
        
        const fetchTonPrice = async () => {
            try {
                const responseList = await GetListReferral(dataJson.token);
                console.log(responseList.data);
                if (responseList.status.code !== 440) {
                    setSelectedList(responseList.data);
                } else {
                    setSelectedList([]);
                }
            } catch (error) {
                // console.error(error.message);
            }
        };
        fetchTonPrice();
    }, []);

    return (
        <>
            <div className='text-center commission-wrap'>
                <div className='commission-top mb-5'>
                    <h2>List Referral</h2>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <Link to="/package" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Package</Link>
                        <Link to="/history" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >History</Link>
                        <Link to="/main" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Home</Link>
                    </div>
                    <table className='mt-3 list-referral-wrap mb-3' style={{width: '100%'}}>
                        <thead style={{textAlign: 'center'}}>
                            <tr>
                                <td style={{width: '30%'}}>Id</td>
                                <td style={{width: '30%'}}>Fullname</td>
                                <td>Created At</td>
                            </tr>
                        </thead>
                        <tbody style={{textAlign: 'center'}}>
                            {
                                currentItems.length ?
                                    currentItems.map((item, index) => {
                                        return (
                                            <tr className="text-center" key={index}>
                                                <td><span>{item.id}</span></td>
                                                <td>
                                                    <span className="fw-bolder">{item.fullname}</span>
                                                </td>
                                                <td><span className="fw-bolder fs-small">{moment.unix(parseInt(item.createdAtTimestamp)).tz("UTC").format("MM/DD/YYYY HH:mm:ss")}</span></td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="text-center">
                                                <span className="d-block text-center text-warning mt-3 mb-3 fs-4">No data.</span>
                                            </div>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    {
                        selectedList.length ?
                            <Pagination className="justify-content-end">
                                <Pagination.Prev
                                    onClick={() =>
                                        setCurrentPage((prevPage) =>
                                            prevPage > 1 ? prevPage - 1 : prevPage
                                        )
                                    }
                                    disabled={prevDisabled}
                                >
                                    <FiChevronsLeft />
                                </Pagination.Prev>
                                {pageNumbers.map((number) => (
                                    <Pagination.Item
                                        key={number}
                                        active={number === currentPage}
                                        onClick={() => handlePageChange(number)}
                                    >
                                        {number}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next
                                    onClick={() =>
                                        setCurrentPage((prevPage) =>
                                            prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                        )
                                    }
                                    disabled={nextDisabled}
                                >
                                    <FiChevronsRight />
                                </Pagination.Next>
                            </Pagination>
                            :
                            ""
                    }
                </div>
            </div>
        </>
    );
};
const ReferralList = ({ jsonString }) => {
    return (
        <ReferralListWrap jsonString={jsonString} />
    );
};

export default ReferralList;