import React, { useState, useEffect } from "react";
const LoadingWrap = () => {
    return (
        <>
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </>
    );
}

export default LoadingWrap;
