import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import moment from 'moment';
import 'moment-timezone';

import Pagination from 'react-bootstrap/Pagination';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';
import FCoin_Icon from '../../../assets/images/icons/FCoin_t.png';

import Coper_Icon from '../../../assets/images/icons/bronze.png';
import Silver_Icon from '../../../assets/images/icons/silver.png';
import Gold_Icon from '../../../assets/images/icons/gold.png';
import Diamond_Icon from '../../../assets/images/icons/diamond.png';

import {
    GetHistoryCommissionTopup,
} from '../../../api/index';

const CommissionTopupWrap = ({ jsonString }) => {

    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const dataJson = JSON.parse(jsonString);

    useEffect(() => {
        const historyCommissionTopup = async () => {
            try {
                const responseList = await GetHistoryCommissionTopup(dataJson.token);
                if (responseList.status.code !== 440) {
                    setSelectedList(responseList.data);
                } else {
                    setSelectedList([]);
                }
            } catch (error) {
                // console.error(error.message);
            }
        };
        historyCommissionTopup();
    }, []);

    return (
        <>
            <div className='text-center commission-wrap'>
                <div className='commission-top mb-5'>
                    <h2>Commission Topup</h2>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <Link to="/package" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Package</Link>
                        <Link to="/history" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >History</Link>
                        <Link to="/main" className="btn btn-primary fw-bolder rounded-2 py-6 text-capitalize btn pixel-btn btn-membership mt-2" >Home</Link>
                    </div>
                    <table className='mt-3 package-info-wrap mb-3' style={{width: '100%'}}>
                        <thead style={{textAlign: 'center'}}>
                            <tr>
                                <td style={{width: '10%'}}>ID</td>
                                <td style={{width: '10%'}}>Tie</td>
                                <td style={{width: '30%'}}>Package</td>
                                <td>Commission</td>
                            </tr>
                        </thead>
                        <tbody style={{textAlign: 'center'}}>
                            {
                                currentItems.length ?
                                    currentItems.map((item, index) => {
                                        return (
                                            <tr className={(item.package === "BRONZE") ? 'text-center copper-item' :
                                            (item.package === "SILVER") ? 'text-center silver-item' :
                                            (item.package === "GOLD") ? 'text-center gold-item' :
                                            (item.package === "DIAMOND") ? 'text-center diamond-item' :
                                            'text-center'} key={index}>
                                                <td><span>{item.fromDownline}</span></td>
                                                <td>
                                                    <span className="fw-bolder">{item.fromTie}</span>
                                                </td>
                                                <td>
                                                    {item.package === "BRONZE" ? <><img src={Coper_Icon} alt="" className="img-fluid" width="16" height="16" /><span className='ps-1'>BRONZE</span></> : ""}
                                                    {item.package === "SILVER" ? <><img src={Silver_Icon} alt="" className="img-fluid" width="16" height="16" /><span className='ps-1'>SILVER</span></> : ""}
                                                    {item.package === "GOLD" ? <><img src={Gold_Icon} alt="" className="img-fluid" width="16" height="16" /><span className='ps-1'>GOLD</span></> : ""}
                                                    {item.package === "DIAMOND" ? <><img src={Diamond_Icon} alt="" className="img-fluid" width="16" height="16" /><span className='ps-1'>DIAMOND</span></> : ""}
                                                </td>
                                                <td>
                                                <div className='d-flex gap-2 justify-content-center'><span className='number'>{item.commission} <img src={FCoin_Icon} alt="" className="img-fluid" width="16" height="16" /></span></div>
                                                </td>
                                                
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="text-center">
                                                <span className="d-block text-center text-warning mt-3 mb-3 fs-4">No data.</span>
                                            </div>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    {
                        selectedList.length ?
                            <Pagination className="justify-content-end">
                                <Pagination.Prev
                                    onClick={() =>
                                        setCurrentPage((prevPage) =>
                                            prevPage > 1 ? prevPage - 1 : prevPage
                                        )
                                    }
                                    disabled={prevDisabled}
                                >
                                    <FiChevronsLeft />
                                </Pagination.Prev>
                                {pageNumbers.map((number) => (
                                    <Pagination.Item
                                        key={number}
                                        active={number === currentPage}
                                        onClick={() => handlePageChange(number)}
                                    >
                                        {number}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next
                                    onClick={() =>
                                        setCurrentPage((prevPage) =>
                                            prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                        )
                                    }
                                    disabled={nextDisabled}
                                >
                                    <FiChevronsRight />
                                </Pagination.Next>
                            </Pagination>
                            :
                            ""
                    }
                </div>
            </div>
        </>
    );
};
const CommissionTopup = ({ jsonString }) => {
    return (
        <CommissionTopupWrap jsonString={jsonString} />
    );
};

export default CommissionTopup;