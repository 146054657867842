import React, { useEffect, useState } from 'react';
import { TelegramProvider, useTelegram } from "./lib/TelegramProvider"
import PlayGameWebApp from './playWebApp';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { QRCode } from 'react-qrcode-logo';
import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/images/json/loadingAnimation.json'

import {
    LoginWebApp
} from '../../api/index';

const WebApp = ({ onScriptLoad }) => {

    const { user, webApp } = useTelegram();

    const telegramWebApp = localStorage.getItem("telegramWebApp") && localStorage.getItem("telegramWebApp").replace(/"/g, '');
    

    const [jsonString, setJsonString] = useState(null);

    useEffect(() => {
        // Execute actions when the user state is updated
        //console.log('User data in WebApp:', user);
        if (user) {
            getDataUser(user);
        }
        // getDataUser(user);
    }, [user]);

    const getDataUser = async (user) => {
        
        // const loginPayload = {
        //     telegramId: 860748460,
        //     first_name: 'Harry Nguyen',
        //     last_name: '|',
        //     platform: 'WEBAPP_TELEGRAM'
        // };

        const loginPayload = {
            telegramId: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            platform: 'WEBAPP_TELEGRAM'
        };
        const loginWebApp = await LoginWebApp(loginPayload);
        if (loginWebApp.data.data) {
            let dataJson = {
                id: loginWebApp.data.data.id,
                token: loginWebApp.data.data.token,
                first_name: loginWebApp.data.data.first_name,
                last_name: loginWebApp.data.data.last_name,
                hash_infor: telegramWebApp
            }
            setJsonString(JSON.stringify(dataJson))
            //console.log(JSON.stringify(dataJson))
        }
    }

    if (isMobile) {
        return (
            <div>
                {
                    jsonString != null ?
                        <PlayGameWebApp jsonString={jsonString} />
                        :
                        <div className='qr-wrap text-center d-flex align-items-center justify-content-center flex-column'>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: loadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                height={200}
                                width={200}
                            />
                            <h3 className='mb-3'>Connecting WebApp.....</h3>
                        </div>
                }
            </div>
        )
    } else {
        return (
            <div>
                {
                    jsonString != null ?
                        <PlayGameWebApp jsonString={jsonString} />
                        :
                        <div className='qr-wrap text-center d-flex align-items-center justify-content-center flex-column'>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: loadingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                height={200}
                                width={200}
                            // isStopped={this.state.isStopped}
                            // isPaused={this.state.isPaused}
                            />
                            <h3 className='mb-3'>Connecting WebApp.....</h3>
                        </div>
                }
            </div>
        )
        // return (
        //     <div className='qr-wrap text-center d-flex align-items-center justify-content-center flex-column'>
        //         <h3 className='mb-3'>Please, play game on your <br /> Telegram WebApp mobile</h3>
        //         {
        //             jsonString != null ?
        //                 <QRCode
        //                     value={`https://t.me/tfarmpixelBot?start=${user.id}`}
        //                     size={200}
        //                     logoImage='https://tfarm.io/icontFarm.png'
        //                     qrStyle='dots'
        //                     logoWidth={50}
        //                     bgColor='#FFFFFF'
        //                     logoOpacity={1}
        //                     fgColor='#000000'
        //                     quietZone={10}
        //                     ecLevel='L'
        //                     eyeRadius={100}
        //                 />
        //                 :
        //                 <>
        //                     <Lottie
        //                         options={{
        //                             loop: true,
        //                             autoplay: true,
        //                             animationData: loadingAnimation,
        //                             rendererSettings: {
        //                                 preserveAspectRatio: 'xMidYMid slice'
        //                             }
        //                         }}
        //                         height={200}
        //                         width={200}                       
        //                     />
        //                 </>
        //         }
        //     </div>
        // )
    }
}

const WithTelegramProvider = () => {
    return (
        // <WebApp />
        <TelegramProvider>
            <WebApp />
        </TelegramProvider>
    )
}
export default WithTelegramProvider;