import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Unity, useUnityContext, RegisterExternalListener } from "react-unity-webgl";

import axios from 'axios';

import moment from 'moment';
import 'moment-timezone';

import MarketAppWrap from './markets';
import { useUser } from '../../context/UserContext';

import {
    GetListReferral
} from '../../api/index';


const PlayGameMobile = ({ jsonString }) => {

    const token = localStorage.getItem("token");
    const user_data = localStorage.getItem("user");

    const { user, setUser } = useUser();

    // exchange rate Ton to Tplant

    const [exchangeTplantUsdt, setExchangeTplantUsdt] = useState(0);
    const [exchangeTplantTon, setExchangeTplantTon] = useState(0);
    const [tonAccountBalance, setTonAccountBalance] = useState(0);


    const {
        unityProvider, requestFullscreen, isLoaded, loadingProgression,
        addEventListener, removeEventListener, sendMessage
    } = useUnityContext({
        loaderUrl: `${process.env.PUBLIC_URL}/playGame/build/Build.loader.js`,
        dataUrl: `${process.env.PUBLIC_URL}/playGame/build/webgl.data`,
        frameworkUrl: `${process.env.PUBLIC_URL}/playGame/build/build.framework.js`,
        codeUrl: `${process.env.PUBLIC_URL}/playGame/build/build.wasm`,
        // streamingAssetsUrl: "streamingassets",
        // webGLContextAttributes: {
        //     alpha: true,
        //     antialias: true,
        //     depth: true,
        //     failIfMajorPerformanceCaveat: true,
        //     powerPreference: "high-performance",
        //     premultipliedAlpha: true,
        //     preserveDrawingBuffer: true,
        //     stencil: true,
        //     desynchronized: true,
        //     xrCompatible: true,
        // },
    });

    const loadingPercentage = Math.round(loadingProgression * 100);
    const unityRef = useRef();

    const refreshPage = () => {
        window.location.reload(false);
    }

    /// From React to Unity
    //  gameObjectName: GameManager,
    //  methodName: MessageFromjavaScript,
    //  parameter: message
    function handleLoadFirstData(isLoaded) {
        if (isLoaded) {
            if (jsonString != null) {
                sendMessage("GameManager", "MessageFromjavaScript", jsonString);
            }
        }
    }

    // From Unity To React
    const returnUnityToReact = useCallback((message) => {
        if (message === 'reloadPage') {
            refreshPage();
        }
    }, []);

    useEffect(() => {
        addEventListener("gamePlay", returnUnityToReact);
        return () => {
            removeEventListener("gamePlay", returnUnityToReact);
        };
    }, [addEventListener, removeEventListener, returnUnityToReact]);

    useEffect(() => {
        handleLoadFirstData(isLoaded);
    }, [isLoaded]);

    useEffect(() => {
        const fetchData = async () => {
            const pool = process.env.REACT_APP_POOL;
            const TonApiKey = process.env.REACT_APP_TON_API_KEY;

            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/blockchain/accounts/${pool}/methods/get_reserves`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}` 
                }
            };
            try {
                const response = await axios.request(config);
                const data = response.data;
                if (data) {
                    const reserve0 = data.decoded.reserve0;
                    const reserve1 = data.decoded.reserve1;
                    const price = reserve0 / reserve1;
                    //console.log(price);
                    setExchangeTplantTon(price);
                }
            } catch (error) {
                // console.error('Error fetching data:', error);
            }

            const addressBalance = user ? user.nonBounceableAddress : "";
            const config_addressBalance = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/blockchain/accounts/${addressBalance}`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}` 
                }
            };

            try {
                const response = await axios.request(config_addressBalance);
                const data = typeof response.data == 'undefined' ? null : response.data;
                if (data != null) {
                    const balance = data.balance / 10 ** 9;
                    //console.log(balance);
                    setTonAccountBalance(balance);
                } else{
                    setTonAccountBalance(0)
                }
            } catch (error) {
                // console.error('Error fetching data:', error);
            }

            const config_addressBalanceUsdt = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/rates?tokens=${process.env.REACT_APP_MASTER_ADDRESS}&currencies=usd`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}` 
                }
            };

            try {
                const response = await axios.request(config_addressBalanceUsdt);
                const data = typeof response.data == 'undefined' ? null : response.data;
                if (data != null) {
                    const ratesArray = Object.entries(data.rates);
                    //console.log(ratesArray[0][1].prices.USD)
                    setExchangeTplantUsdt(ratesArray[0][1].prices.USD);
                } else{
                    setExchangeTplantUsdt(0)
                }
            } catch (error) {
                // console.error('Error fetching data:', error);
            }


        };

        

        // Call API initially
        fetchData();

        // Call API every 10 seconds
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);


    return (
        <>
            
            <div ref={unityRef} className='text-center mainApp-wrap'>
                <div className='mainApp-wrap-container-wrap'>
                    <MarketAppWrap jsonString={jsonString} />
                </div>
            </div>
        </>
    );
};
const MarketWebApp = ({ jsonString }) => {
    return (
        <PlayGameMobile jsonString={jsonString} />
    );
};

export default MarketWebApp;