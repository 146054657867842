import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { UserProvider } from './context/UserContext';
import { ToastProvider } from 'rc-toastr';

import { BrowserRouter } from 'react-router-dom';
import "rc-toastr/dist/index.css";

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <UserProvider>
      <BrowserRouter>
        <ToastProvider config={{
          position: "top-right",
          duration: 2000,
          zIndex: 9999
        }} >
          <App />
        </ToastProvider>
      </BrowserRouter>
    </UserProvider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
