import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useToast, ToastProvider } from 'rc-toastr';
import { QRCode } from 'react-qrcode-logo';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FcInfo } from "react-icons/fc";
import {
    FiCopy
} from "react-icons/fi";

import WithDraw from "./withDraw";
import ConvertTplantToTon from "./convertTplantToTon";
import TransferTfarm from "./transferTfarm";
import Deposit from "./deposit";
import WithDrawTplantWebApp from "./withDrawTplant";
import ConvertTplantOnChainWebApp from "./convertTplant";
import AccumulativeTplantWebApp from "./accumulateTplant";

import Ton_Icon from '../../../assets/images/icons/ton_symbol.svg';
import Logo from '../../../assets/images/Logo.png';

import {
    ConvertTONtoTFARM,
    GetTONPrice,
    GetHistoryConvert,
    GetInfo
} from '../../../api/index';

import { useUser } from '../../../context/UserContext';


const ConvertWebAppWrap = ({ jsonString, exchangeTplantTon, tonAccountBalance }) => {
    const user_webapp = localStorage.getItem("user_webapp");

    const token = localStorage.getItem("token");
    const { toast } = useToast();
    const { user, setUser } = useUser();

    useEffect(() => {
        const fetchTonPrice = async () => {
            try {
                const response = await GetTONPrice(token);
                localStorage.setItem('tonPrice', response.TON_prices_USDT ? response.TON_prices_USDT : 0);
            } catch (error) {
                // console.error(error.message);
            }
        };
        fetchTonPrice();
    }, []);

    return (
        <React.Fragment>
            <div className="convert-wrap">
                <div className="membership-item convert-wrap-item convert-app-main gap-2">
                    <Tabs
                        defaultActiveKey="Deposit To Game"
                        className="mb-3 convert-app-tab"
                        variant="pills"
                    >
                        <Tab eventKey="Deposit To Game" title={<>Deposit To Game</>}>
                            <div className='mainApp-wrap-container'>
                                <Deposit />
                            </div>
                        </Tab>
                        {/* <Tab eventKey="Transfer Tfarm" title={<>Transfer TFARM</>}><TransferTfarm /></Tab> */}
                        <Tab eventKey="ConvertT TPLANT" title={<>Convert TPLANT</>}><ConvertTplantOnChainWebApp /></Tab>
                        <Tab eventKey="Convert Ton to Tfarm" title={<>TON {'=>'} TFARM</>}><WithDraw tonAccountBalance={tonAccountBalance} /></Tab>
                        {/* <Tab eventKey="Convert TPLANT to TON" title={<>TPLANT {'=>'} TON</>}><ConvertTplantToTon exchangeTplantTon={exchangeTplantTon} /></Tab> */}
                        <Tab eventKey="Accumulative TPLANT" title={<>Accumulative TPLANT</>}><AccumulativeTplantWebApp exchangeTplantTon={exchangeTplantTon} /></Tab>
                        <Tab eventKey="Withdraw TPLANT" title={<>Withdraw TPLANT Accumulate</>}><WithDrawTplantWebApp exchangeTplantTon={exchangeTplantTon} /></Tab>

                    </Tabs>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ConvertWebAppWrap;
