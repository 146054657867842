import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

import { FiArrowDown } from "react-icons/fi";

import moment from 'moment';
import 'moment-timezone';
import {
    ConvertTPLANTtoTON,
    GetHistoryConvertTPLANTtoTON,
} from '../../api/index';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import { useUser } from '../../context/UserContext';

import LoadingWrap from "../../components/Loading";
import Ton_Icon from '../../assets/images/icons/ton_symbol.svg';
import FCoin_Icon from '../../assets/images/icons/FCoin_t.png';
import TCoin_Icon from '../../assets/images/icons/FCoin.png';
import Loss_Icon from '../../assets/images/icons/loss.png';


const ConvertTplantToTon = ({ userInfo, tonPrice }) => {

    const token = localStorage.getItem("token");
    const { user, setUser } = useUser();

    const ratio = 0.001;

    const [amountTplant, setAmountTo] = useState(0);
    const [amountTon, setAmountTon] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const [estimatedTime, setEstimatedTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [convertMessage, setConvertMessage] = useState('');
    const [convertMessageErr, setConvertMessageErr] = useState('');

    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleInputChange = (event) => {
        let value = event.target.value;

        // Loại bỏ mọi ký tự không phải số hoặc dấu chấm thập phân từ giá trị
        value = value.replace(/[^0-9.]/g, '');

        // Đảm bảo chỉ có một dấu chấm thập phân được nhập vào
        const decimalCount = (value.match(/\./g) || []).length;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }

        const numericValue = Number(value);
        // Kiểm tra giá trị và đặt thông báo lỗi nếu cần
        if (numericValue < 100) {
            setErrorMessage('The value must be greater than or equal to 100.');
        } else {
            setErrorMessage('');
        }

        // Cập nhật giá trị vào state
        setAmountTo(value);
        setAmountTon(value * ratio)
    };

    const handleMax = () => {
        setAmountTo(user.TPLANT_Balance);
        setAmountTon(user.TON_Balance);
    }

    const handleEstimatedTime = () => {
        const currentTime = moment();
        // Cộng thêm 20 giây
        const estimatedTime = currentTime.add(20, 'seconds');
        // Format thời gian theo định dạng mong muốn
        const formattedTime = estimatedTime.format('D/M/YYYY, HH:mm:ss');
        // Gán thời gian vào state
        setEstimatedTime(formattedTime);
    }

    const handleConvert = async () => {
        setLoadingSend(true);
        const data = {
            fromToken: "TPLANT",
            toToken: "TON",
            amountFrom: amountTplant
        };
        handleEstimatedTime();

        const responseConvert = await ConvertTPLANTtoTON(data, token);
        //console.log(responseConvert);
        if (responseConvert) {
            if (responseConvert.status.code === 400) {
                setConvertMessageErr(responseConvert.status.message);
                setConvertMessage('');
                setEstimatedTime('');
            }
            if (responseConvert.status === 200) {
                setConvertMessage(responseConvert.data.status.message);
                setConvertMessageErr("");
                setAmountTo(0);
                setAmountTon(0);
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            }
            setLoadingSend(false);
        }
    }

    useEffect(() => {
        const fetchTonPrice = async () => {
            try {
                const responseList = await GetHistoryConvertTPLANTtoTON(token);
                if (responseList.status.code !== 440) {
                    setSelectedList(responseList.data);
                } else {
                    setSelectedList([]);
                }
            } catch (error) {
                console.error(error.message);
            }
        };
        fetchTonPrice();
    }, []);

    return (
        <React.Fragment>
            <section className="convert_section">
                <div className="container position-relative">
                    {/* {
                        loading && 
                        <div className="loading-wrap">
                            <LoadingWrap />
                        </div>
                    } */}
                    {
                        loadingSend &&
                        <div className="loading-wrap">
                            <div className="loading-send">
                                <img src={Loss_Icon} alt="" />
                                <h3>Convert TPLANT</h3>
                                <div className="popup-grey-text">Please wait a few seconds for your<br />transaction to be processed..</div>
                            </div>
                        </div>
                    }
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="">
                                <div className="convertBoxTop">
                                    <h2 className="convertBoxTitle">Convert TPLANT to TON</h2>
                                    <div className="convertBoxIn d-flex">
                                        <div className="convertBoxIn_Left">
                                            <h3>Input TPLANT amount</h3>
                                            <input
                                                id="token-amout-input"
                                                className="input-group"
                                                placeholder="0.000"
                                                value={amountTplant}
                                                onChange={handleInputChange}
                                            />
                                            <p className="number">${amountTplant * ratio * tonPrice}</p>
                                            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                        </div>
                                        <div className="convertBoxIn_Right">
                                            <div className="coinWrap d-flex align-items-center">
                                                <img src={TCoin_Icon} alt="" />
                                                <span>TPLANT</span>
                                            </div>
                                            <div className="availableBox">
                                                <p className="">Available: <span>{userInfo && userInfo.TPLANT_Balance}</span></p>
                                                <p className="text-primary" onClick={handleMax}>MAX</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="convertBoxIcon">
                                        <div className="icon">
                                            <FiArrowDown />
                                        </div>
                                    </div>
                                    <div className="convertBoxOut d-flex">
                                        <div className="convertBoxIn_Left">
                                            <h3>You'll Receive</h3>
                                            <input id="token-amout-output" disabled autoComplete="false" value={amountTon} placeholder="0.000" className="input-group" />
                                            <p className="number">${amountTon * tonPrice}</p>
                                        </div>
                                        <div className="convertBoxIn_Right">
                                            <div className="coinWrap d-flex align-items-center">
                                                <img src={Ton_Icon} alt="" />
                                                <span>TON</span>
                                            </div>
                                            <div className="availableBox opacity-0">
                                                <p className="">Available: 0</p>
                                                <p className="text-primary">MAX</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="mt-3">You will convert from on-chain.</h3>
                                    {
                                        estimatedTime && <h3 className="mb-0">Estimate wait time: {estimatedTime}</h3>
                                    }
                                    {
                                        convertMessage && <h3 className="mb-0 text-success">{convertMessage}</h3>
                                    }
                                    {
                                        convertMessageErr && <h3 className="mb-0 text-danger">{convertMessageErr}</h3>
                                    }
                                    <button className="btn pixel-btn" onClick={handleConvert} disabled={!amountTplant}>Request Convert</button>
                                </div>
                                <div className="convertBoxBottom">
                                    <h2 className="convertBoxTitle">Convert History</h2>
                                    <Table responsive className="table-hover table-bordered">
                                        <thead>
                                            <tr className="text-center">
                                                <th>TxId</th>
                                                <th>Date</th>
                                                <th>AmountConvert</th>
                                                <th>AmountTo</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentItems.length ?
                                                    currentItems.map((item, index) => {
                                                        return (
                                                            <tr className="text-center" key={index}>
                                                                <td><span>{item.convertId}</span></td>
                                                                <td><span className="text-primary fw-bolder">{moment.unix(parseInt(item.createdAtTimestamp)).tz("UTC").format("MM/DD/YYYY HH:mm:ss")}</span></td>
                                                                <td>
                                                                    <span className="fw-bolder">{item.amountConvert} <img src={TCoin_Icon} alt="" width={16} /></span>
                                                                </td>
                                                                <td>
                                                                    <span className="fw-bolder">{item.amountTo} <img src={Ton_Icon} alt="" width={16} /></span>
                                                                </td>
                                                                <td>
                                                                    <span className="fw-bolder">{item.status}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className="text-center">
                                                                <span className="d-block text-center text-warning mt-3 fs-3">No data.</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    {
                                        selectedList.length ?
                                            <Pagination className="justify-content-end">
                                                <Pagination.Prev
                                                    onClick={() =>
                                                        setCurrentPage((prevPage) =>
                                                            prevPage > 1 ? prevPage - 1 : prevPage
                                                        )
                                                    }
                                                    disabled={prevDisabled}
                                                >
                                                    <FiChevronsLeft />
                                                </Pagination.Prev>
                                                {pageNumbers.map((number) => (
                                                    <Pagination.Item
                                                        key={number}
                                                        active={number === currentPage}
                                                        onClick={() => handlePageChange(number)}
                                                    >
                                                        {number}
                                                    </Pagination.Item>
                                                ))}
                                                <Pagination.Next
                                                    onClick={() =>
                                                        setCurrentPage((prevPage) =>
                                                            prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                                        )
                                                    }
                                                    disabled={nextDisabled}
                                                >
                                                    <FiChevronsRight />
                                                </Pagination.Next>
                                            </Pagination>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default ConvertTplantToTon;
